import constant from "./constant";


export class Fab {
    private _image: string;
    private _func: Function;
    constructor(image: string, func: Function) {
        this._image = image;
        this._func = func;
    }
    src = () => {
        return constant.src(this._image);
    };
    func = () => {
        return this._func();
    };
    image = () => {
        return this._image;
    }
    isMdi = () => {
        return this._image && this._image.startsWith("mdi-");
    };
}
/*
const createFab = (image, func) => {
    const ret = {};

    ret.src = () => {
        return constant.src(image);
    };
    ret.func = () => {
        return func();
    };
    ret.image = () => {
        return image;
    }
    ret.isMdi = () => {
        return image && image.startsWith("mdi-");
    };

    return ret;
};
*/
const createFab = (image: string, func: Function) => {
    return new Fab(image, func);
}
export default createFab;