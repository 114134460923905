
/** フロア セル情報 */
export class Cell{
    public floorCode: string;
    public row: number;
    public col: number;
    public title: string;
    public fontSize: string;
    public align: string;
    public image: string;
    public top: boolean;
    public bottom: boolean;
    public left: boolean;
    public right: boolean;

    private readonly UnknowCell = -1;

    constructor(init?: Partial<Cell>) {
        this.floorCode = init?.floorCode ?? null;
        this.row = init?.row ?? this.UnknowCell;
        this.col = init?.col ?? this.UnknowCell;
        this.title = init?.title ?? null;
        this.fontSize = init?.fontSize ?? null;
        this.align = init?.align ?? null;
        this.image = init?.image ?? null;
        this.top = init?.top ?? false;
        this.bottom = init?.bottom ?? false;
        this.left = init?.left ?? false;
        this.right = init?.right ?? false;

    }

    isEmpty = () => {
        return this.row === this.UnknowCell || this.col === this.UnknowCell;
    }
    match = (row, col) => {
        return !this.isEmpty() && this.row === row && this.col === col;
    }
    getAlign = () => {
        return this.align ? this.align : "center";
    }
    getWidth = () => {
        return this.getAlign() == "center" ? 10000 : 100;
    }

}

/** フロア セル情報作成 */
const createCell = (model?: Partial<Cell>) => {
    return new Cell(model);
};

export default createCell;