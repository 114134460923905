import constant from "./constant";
import { Detail } from "./model.detail";

/** グラフデータ */
export class ChartData {
    public value: any;
    public color: string;
    public detail: Detail;

    constructor(value, color: string, detail: Detail) {
        this.value = value;
        this.color = color;
        this.detail = detail;
    }
    isEmpty = () => {
        return !this.value;
    }
    tooltip = () => {
        if (!this.detail || this.detail.isEmpty()) {
            return "";
        }

        const timestamp = constant.toDate(this.detail.timestamp).toISOString();
        const tooltip: string[] = [];

        tooltip.push(`日付: ${timestamp.substring(0, 10)} ${timestamp.substring(11, 16)}`);
        tooltip.push("");
        tooltip.push(`CO2濃度: ${constant.dispCo2c(this.detail)}`);
        tooltip.push(`温度: ${constant.dispTemp(this.detail)}`);
        tooltip.push(`湿度: ${constant.dispHumi(this.detail)}`);

        return tooltip.join("\n");
    }
    data = (isDot: boolean) => {
        const styleDot = `point { fill-color: ${this.color}; size: 4; visible: true }`;
        const styleLine = `color: ${this.color}`;

        return [this.value, isDot ? styleDot : styleLine, this.tooltip()];
    }
}

/** グラフデータ作成 */
const createChartData = (value?: any, color?: string, detail?: Detail) => {
    return new ChartData(value, color, detail);

};
export default createChartData;


