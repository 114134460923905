import constant from "./constant";
import { Detail } from "./model.detail";
import { Strategy } from "./model.strategy.base";
import { User } from "./model.user";

export class StrategyUser extends Strategy {

    private readonly MessageGreen = "適正に換気されています";

    constructor(init?: Partial<User>) {
        super(init);
    }

    enabled = () => {
        return false;
    }

    getLevelRed = () => {
        return constant.yellow();
    }

    getFaceRed = () => {
        return "yellow";
    }

    getGuideGreen(isFloor: boolean) {
        return isFloor ? this.MessageGreen : "快適";
    }

    getGuideYellow = () => {
        return constant.dispCo2c(this.toDetail(this.getHierarchy().lowerYellow)) + "以上";
    }

    getGuideRed = () => {
        return "";
    }

    getMessage = (value: Detail | number) => {
        const detail = this.toDetail(value);
        if (detail.isEmpty()) {
            if (detail.errorReason) {
                return "メンテナンス中";
            }

            return "";
        }

        if (!this.geYellow(detail)) {
            return this.MessageGreen;
        }

        return "";
    }

    getIcon = () => {
        return "";
    }

    getDefaultScreen = () => {
        return constant.screenList();
    }


}

