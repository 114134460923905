import constant from "./constant";
import { AreaSearchCondition } from "./model.area.search.condition";
import { Hierarchy } from "./model.hierarchy";
import createPointSensor, { PointSensor } from "./model.point.sensor";

export class Point {
    public hierarchyCode: string;
    public hierarchyName: string;
    public countGreen: number;
    public countYellow: number;
    public countRed: number;
    public countSensor: number;
    public pointSensorList: PointSensor[];

    constructor(init?: Partial<Point>) {
        this.hierarchyCode = init?.hierarchyCode ?? null;
        this.hierarchyName = init?.hierarchyName ?? null;
        this.countGreen = init?.countGreen ?? 0;
        this.countYellow = init?.countYellow ?? 0;
        this.countRed = init?.countRed ?? 0;
        this.countSensor = init?.countSensor ?? 0;
        this.pointSensorList = init?.pointSensorList ?? [];
        this.pointSensorList = init?.pointSensorList ?
            init.pointSensorList.map(pointSensor => createPointSensor(pointSensor)) : [];
    }

    isNotEmpty = () => {
        return this.hierarchyCode;
    };
    getWeight = (count: number) => {
        return (count / (this.countSensor || 1));
    };
    getWeightGreen = () => {
        return this.getWeight(this.countGreen);
    };
    getWeightYellow = () => {
        return this.getWeight(this.countYellow);
    };
    getWeightRed = () => {
        return this.getWeight(this.countRed);
    };
    getWeightTotal = () => {
        return this.getWeight(this.countGreen + this.countYellow + this.countRed);
    };
    sort = () => {

        //固定のソート順
        this.pointSensorList.sort((a, b) => {
            return a.sensor.sortOrder - b.sensor.sortOrder;
        });
        //色によるソート
        // this.pointSensorList.sort((a, b) => {
        //     const ca = a.getColor();
        //     const cb = b.getColor();

        //     if (cb !== ca) {
        //         if (ca === constant.gray() || cb === constant.gray()) {
        //             return ca === constant.gray() ? 1 : -1;
        //         } else {
        //             return cb > ca ? 1 : -1;
        //         }
        //     }

        //     if (ca === constant.red()) {
        //         return b.countRed - a.countRed;
        //     }

        //     if (ca === constant.yellow()) {
        //         return b.countYellow - a.countYellow;
        //     }

        //     return b.countGreen - a.countGreen;
        // });
    };
    filterPointSensorList = (condition: AreaSearchCondition, hierarchy: Hierarchy) => {
        return this.pointSensorList.filter((ps) => ps.show(condition, hierarchy));
    };
    count = (condition: AreaSearchCondition, hierarchy: Hierarchy) => {
        return this.filterPointSensorList(condition, hierarchy).length;
    };
    initVisibleTooltip = () => {
        this.pointSensorList.forEach((ps) => ps.visibleTooltip = false);
    };

}

const createPoint = (model?: Point) => {
    return new Point(model);
};

export default createPoint;