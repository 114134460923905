import constant from "./constant";
import { Detail } from "./model.detail";
import { Strategy } from "./model.strategy.base";

import { User } from "./model.user";

export class StrategyManager extends Strategy {

	constructor(init?: Partial<User>) {
		super(init);
	}

	enabled = () => {
		return true;
	}

	getLevelRed = () => {
		return constant.red();
	}

	getFaceRed = () => {
		return "red";
	}

	getGuideGreen = () => {
		return "良好";
	}

	getGuideYellow = () => {
		return "要注意";
	}

	getGuideRed = () => {
		return "要換気";
	}

	getMessage(value: Detail | number) {
		const detail = this.toDetail(value);
		if (detail.isEmpty()) {
			if (detail.errorReason == constant.grayNone()) {
				return "データが取得できていません";
			}
			if (detail.errorReason == constant.grayOverflow()) {
				return "センサーの電源を抜き差ししてください";
			}
			return "";
		}

		if (this.geRed(detail)) {
			return "換気をしてください";
		}

		if (this.geYellow(detail)) {
			return "そろそろ換気が必要です";
		}

		return "";
	}

	getIcon = (value: Detail | number) => {
		const detail = this.toDetail(value);
		if (detail.isEmpty()) {
			if (detail.errorReason == constant.grayNone()) {
				return require("../assets/icon-excl.png");
			}
			if (detail.errorReason == constant.grayOverflow()) {
				return require("../assets/icon-power-plug.png");
			}
			return "";
		}

		if (this.geRed(detail)) {
			return require("../assets/icon-excl.png");
		}

		if (this.geYellow(detail)) {
			return require("../assets/icon-excl.png");
		}

		return "";
	}

	getDefaultScreen = () => {
		return this.getUser().isStoreManager() ? constant.screenFloor() : constant.screenArea();
	}

}
