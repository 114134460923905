import axios from "axios";
import models from "./models";
import constant from "./constant";
import { CsvInfo, ICsvAsyncStoreParam, ICsvInfoResponse } from "./model.csv.info";
import '../types/async-api';
import { SearchCondition } from "./model.search.condition";

const storeDownloadState = {
    csv: null,
    filename: null,
    csvInfo: null as CsvInfo,
};
type StoreDownload = typeof storeDownloadState;

const storeDownload = {
    namespaced: true,
    state: storeDownloadState,
    
    getters: {
        /**
         * Csv情報を取得
         */
        csvInfo: (state: StoreDownload) => {
            console.log("get csvinfo:", state.csvInfo);
            return state.csvInfo;
        },
    },
    mutations: {
        csv(state: StoreDownload, result: any) {
            state.csv = result.data;
            state.filename = result.filename;
        },
        /**
         * CSV情報の作成(非同期用)
         * @param state 
         * @param param 
         */
        createCsvInfo(state: StoreDownload, param: ICsvAsyncStoreParam) {
            console.log("createCsvInfo:", param);

            state.csvInfo = models.createCsvInfo(param.condition, param.sensorCount);
        },
        /**
         * CSV情報の結果をセット(非同期用)
         * @param state 
         * @param result 
         */
        setCsvResponseData(state: StoreDownload, result: ICsvInfoResponse) {
            console.log("setCsvResponseData:", result);
            state.csvInfo.setResponseData(result);
            //変更通知のため再セット
            state.csvInfo = Object.assign({}, state.csvInfo);
        },
        /**
         * CSV情報のエラー結果をセット(非同期用)
         * @param state 
         * @param result 
         */
         setCsvResponseError(state: StoreDownload, result: string) {
            console.log("setCsvResponseData:", result);
            state.csvInfo.setResponseError(result);
            //変更通知のため再セット
            state.csvInfo = Object.assign({}, state.csvInfo);
        },        
    },
    actions: {
        csv({ commit }: { commit: any }, condition: any) {
            axios.post(constant.urlDownloadAreaAsync(), condition, models.createAreaCsvRequestConfig()).then((response) => {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; // 正規表現でfilenameを抜き出す
                const matches = filenameRegex.exec(response.headers['content-disposition']);
                let fileName = null;
                if (matches != null && matches[1]) {
                    const filenameReplace = matches[1].replace(/['"]/g, '');
                    fileName = decodeURI(filenameReplace) // 日本語対応
                }
                const result = { data: response.data, filename: fileName };
                commit("csv", result);
            });
        },
        async csvasync({ commit }: { commit: any }, param: ICsvAsyncStoreParam) {
            console.log(param);
            // CSV情報の作成(リクエスト前に分かる情報を登録)
            commit("createCsvInfo", param);

            // CSVの作成処理
            return await axios.post<AsyncApi.AsyncExecuteResponse>(
                constant.urlDownloadAreaAsync(),
                param.condition,
                models.createRequestConfig()
            )
                .then(async (response) => {
                    console.log("HTTP 202 received, polling operation...");
                    console.log("Operation running at " + response.headers.location);

                    // Retrieve the initial operation status
                    let pollingResponse = await axios.get<AsyncApi.AsyncCheckStatusResponse<SearchCondition, ICsvInfoResponse>>(response.headers.location);

                    console.log("Operation status is " + pollingResponse.data.runtimeStatus);

                    // Loop while the operation is still in progress...
                    while (pollingResponse.data.runtimeStatus === "Pending" || pollingResponse.data.runtimeStatus === "Running" || pollingResponse.data.runtimeStatus == null) {
                        //5秒間隔でポーリング
                        await constant.sleepAsync(5000);
                        console.log("Query request start...");

                        pollingResponse = await axios.get<AsyncApi.AsyncCheckStatusResponse<SearchCondition, ICsvInfoResponse>>(response.headers.location);
                        console.log("Operation status is " + pollingResponse.data.runtimeStatus);

                    }
                    console.log("Return result...");

                    if (pollingResponse.data.runtimeStatus === "Completed") {
                        //終了した場合、取得した情報をCsvInfoにセット
                        console.log("Operation succeeded!");
                        console.log("Retrieving resource at " + pollingResponse.data.output.downloadUrl);
                        commit("setCsvResponseData", pollingResponse.data.output);

                    }
                    else {
                        // Treat failures as exceptions, so they can be handled as such
                        console.log(pollingResponse.data);

                        commit("setCsvResponseError", pollingResponse.data.output);

                    }


                });
        }
    },
};

export default storeDownload;