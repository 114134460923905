import constant from "./constant";

export class Header {
    private _title: string;
    public image: string;
    public getTimestamp: () => number;

    constructor(title: string, image: string, getTimestamp: () => number) {
        this._title = title;
        this.image = image;
        this.getTimestamp = getTimestamp;
    }
    title = () => {
        return this._title;
    };

    src = () => {
        return constant.src(this.image);
    };
    dispTimestamp = () => {
        if (!(this.getTimestamp && this.getTimestamp())) {
            return "";
        }
        return constant.toDate(this.getTimestamp()).toISOString().substring(11, 16);
    };

}

const createHeader = (title: string, image: string, getTimestamp: () => number) => {
    return new Header(title, image, getTimestamp);
}

export default createHeader;