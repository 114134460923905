import axios from 'axios';
import constant from './constant';
import models from './models';

const storeUserState = {
    user: null as any
};
type StoreUserState = typeof storeUserState;

const storeUser = {
    namespaced: true,
    state: storeUserState,
    getters: {
        user: (state: StoreUserState) => {
            return state.user
        },
    },
    mutations: {
        login(state: StoreUserState, result: any) {
            state.user = result ? models.createUser(result) : null;
        }
    },
    actions: {
        login({ commit }: { commit: any }, { hash }: { hash: any }) {
            const catchProcessing = (error: any) => {
                console.log('status: ' + error.statusCode || error.response.status);
                commit("login", null);
            };

            return axios
                .get(constant.urlHash(hash), models.createRequestConfig())
                .then((response) => {
                    const up = response.data;
                    return axios
                        .get(constant.urlLogin(), models.createBasicAuthentication(up));
                })
                .then((response) => {
                    commit("login", response.data);
                })
                .catch((error) => {
                    catchProcessing(error);
                });
        },
    },
};

export default storeUser;