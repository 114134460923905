import constant from "./constant";

/**
 * localStorageに関する共通処理 (Store内で使用。)
 */
const funcLocalStorage = {

  /**  
   * 指定したlocalStorageの値を取得して返す関数
   * @param {string} cName 取得したいlocalStorageName 
   * @returns localStorageの値
   */
  getItem(cName) {
    return localStorage.getItem(cName);
  },
  /** 
   * 指定したlocalStorageを追加する関数
   * @param {string} cName 追加するlocalStorageName
   * @param {string} cValue 追加するlocalStorageValue
   */
  setItem(cName, cValue) {
    return localStorage.setItem(cName, cValue);
  },
  /** 
   * 指定したlocalStorageの値を削除する関数
   * @param {string} cName 削除したいlocalStorageName
   */
  deleteItem(cName) {
    return localStorage.removeItem(cName);
  },
  /**
   * 通知音の設定をlocalStorageに保存
   * @param {string[]} ringSensorList 通知音を鳴らすセンサー一覧
   */
  saveRingSensorListToLocalStorage(ringSensorList) {
    const json = JSON.stringify(ringSensorList);
    this.setItem(constant.ringSensorListLocalStorageName(), json);
  },
  /**
   * 通知音の設定をlocalStorageから読み込み
   * @returns {string[]} 通知音を鳴らすセンサー一覧
   */
  readRingSensorListByLocalStorage() {
    const json = this.getItem(constant.ringSensorListLocalStorageName());
    return (json) ? JSON.parse(json) : [];
  },

  /**
   * 全てのセンサの通知音設定一覧をlocalStorageに保存
   * @param {sensorRingInfo[]} sensorRingInfoList 全てのセンサの通知音設定一覧
   */
  saveSensorRingInfoListToLocalStorage(sensorRingInfoList) {
    const json = JSON.stringify(sensorRingInfoList);
    this.setItem(constant.sensorRingInfoListLocalStorageName(), json);
  },

  /**
   * 全てのセンサの通知音設定一覧をlocalStorageから読み込み
   * @returns {string[]} 全てのセンサの通知音設定一覧
   */
  readSensorRingInfoByLocalStorage() {
    const json = this.getItem(constant.sensorRingInfoListLocalStorageName());
    return (json) ? JSON.parse(json) : [];
  },
}

export default funcLocalStorage;