import Vue from "vue";
import VueRouter from "vue-router";
import LoginUnitView from "../views/LoginUnitView.vue";

Vue.use(VueRouter);

const createRoute = (path, name, component) => {
    return {
        path: path,
        name: name,
        component: component,
        props: true,
    };
};
const routeList = [
    createRoute("", "root", LoginUnitView),
    createRoute("/login", "login", LoginUnitView),
    createRoute("/floor", "floor", () => import("../views/FloorUnitView.vue")),
    createRoute("/ranking", "ranking", () => import("../views/RankingUnitView.vue")),
    createRoute("/area", "area", () => import("../views/AreaUnitView.vue")),
    createRoute("/sensor", "sensor", () => import("../views/SensorUnitView.vue")),
];
const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes: routeList,
});

export default router;