import constant from "./constant";
import createHierarchy, { Hierarchy } from "./model.hierarchy";
import createStrategy from "./model.strategy";
import createSearchCondition from "./model.search.condition";
import { Strategy } from "./model.strategy.base";

/** ユーザ情報 */
export class User {

    public userCode: string;
    public userName: string;
    public userKind: number;
    public hierarchy: Hierarchy;
    public initialScreen: string;
    public strategy: Strategy;
    public addPage: any;
    public enableRing: boolean;

    private currentHierarchy: Hierarchy;

    constructor(init?: Partial<User>) {
        this.userCode = init?.userCode ?? null;
        this.userName = init?.userName ?? null;
        this.userKind = init?.userKind ?? constant.userKindUnknown();
        this.hierarchy = init?.hierarchy ? createHierarchy(init.hierarchy) : createHierarchy(null);
        this.initialScreen = init?.initialScreen ?? null;
        this.addPage = init?.addPage ?? null;
        this.enableRing = init?.enableRing ?? false;

        this.strategy = createStrategy(this);

    }

    isUser = () => {
        return !this || this.userKind === constant.userKindUser();
    }

    isManager = () => {
        return !this.isUser();
    }

    isStoreManager = () => {
        return this.userKind === constant.userKindStoreManager();
    }

    isAreaManager = () => {
        return this.userKind === constant.userKindAreaManager();
    }

    unauthorized = () => {
        return !this.userCode;
    }

    getAddPage = () => {
        return this.addPage;
    }

    createSearchCondition = () => {
        const condition = createSearchCondition();
        condition.userKind = this.userKind;
        condition.searchUnit = this.strategy.getSearchUnit();
        condition.hierarchyCode = this.hierarchy.hierarchyCode;

        return condition;
    }

    findHierarchy = (hierarchyCode: string, hierarchy: Hierarchy) => {
        let h = hierarchy || this.hierarchy;
        const chList = h.childHierarchyList;

        if (h.hierarchyCode === hierarchyCode) {
            return h;
        }

        if (!Array.isArray(chList)) {
            return null;
        }

        for (const ch of chList) {
            h = this.findHierarchy(hierarchyCode, ch);

            if (h) {
                return h;
            }
        }

        return null;
    }

    getCurrentHierarchy = () => {
        return this.currentHierarchy || this.hierarchy;
    }

    setCurrentHierarchy = (hierarchy: Hierarchy) => {
        this.currentHierarchy = createHierarchy(hierarchy);
    }

    createSummarySearchCondition = (hierarchyCode: string) => {
        const ssc = createSearchCondition();

        ssc.userKind = this.userKind;
        ssc.searchUnit = constant.searchUnitFloor();
        ssc.hierarchyCode = hierarchyCode;

        return ssc;
    }

}


/** ユーザ情報作成 */
const createUser = (model?: Partial<User>) => {
    return new User(model);
}

// const createUser = (model) => {
//     const newModel = () => {
//         return {
//             userCode: null,
//             userName: null,
//             userKind: constant.userKindUnknown(),
//             hierarchy: null,
//             initialScreen: null,
//             strategy: null,
//             addPage: null,
//             enableRing: false,
//         };
//     };
//     const ret = model || newModel();

//     ret.currentHierarchy = null;
//     ret.hierarchy = createHierarchy(ret.hierarchy);
//     ret.strategy = createStrategy(ret);
//     ret.isUser = () => constant.isUser(ret);
//     ret.isManager = () => !ret.isUser();
//     ret.isStoreManager = () => ret.userKind === constant.userKindStoreManager();
//     ret.isAreaManager = () => ret.userKind === constant.userKindAreaManager();
//     ret.unauthorized = () => {
//         return !ret.userCode;
//     };
//     ret.getAddPage = () => {
//         return ret.addPage;
//     };
//     ret.createSearchCondition = () => {
//         const condition = createSearchCondition();

//         condition.userKind = ret.userKind;
//         condition.searchUnit = ret.strategy.getSearchUnit();
//         condition.hierarchyCode = ret.hierarchy.hierarchyCode;

//         return condition;
//     };
//     ret.findHierarchy = (hierarchyCode, hierarchy) => {
//         let h = hierarchy || ret.hierarchy;
//         const chList = h.childHierarchyList;

//         if (h.hierarchyCode === hierarchyCode) {
//             return h;
//         }

//         if (!Array.isArray(chList)) {
//             return null;
//         }

//         for (const ch of chList) {
//             h = ret.findHierarchy(hierarchyCode, ch);

//             if (h) {
//                 return h;
//             }
//         }

//         return null;
//     };
//     ret.getCurrentHierarchy = () => {
//         return ret.currentHierarchy || ret.hierarchy;
//     };
//     ret.setCurrentHierarchy = (hierarchy) => {
//         ret.currentHierarchy = createHierarchy(hierarchy);
//     };
//     ret.createSummarySearchCondition = (hierarchyCode) => {
//         const ssc = createSearchCondition();

//         ssc.userKind = ret.userKind;
//         ssc.searchUnit = constant.searchUnitFloor();
//         ssc.hierarchyCode = hierarchyCode;

//         return ssc;
//     };

//     return ret;
// };

export default createUser;