import constant from "./constant";
import createCell, { Cell } from "./model.cell";

export class Floor {
    public hierarcyCode: string;
    public floorCode: string;
    public floorName: string;
    public gridRow: number;
    public gridCol: number;
    public cellList: Cell[];

    constructor(init?: Partial<Floor>) {
        this.hierarcyCode = init?.hierarcyCode ?? null;
        this.floorCode = init?.floorCode ?? null;
        this.floorName = init?.floorName ?? null;
        this.gridRow = init?.gridRow ?? 0;
        this.gridCol = init?.gridCol ?? 0;
        this.cellList = init?.cellList ?
            init.cellList.map(cell => createCell(cell)) : [];

    }

    cellLength = (mapLength: number, val: number) => {
        return mapLength / val;
    }

    gridRowCount = () => {
        return this.gridRow;
    }
    gridColCount = () => {
        return this.gridCol;
    }
    cellHeight = () => {
        return this.cellLength(constant.floorHeight(), this.gridRow);
    }
    cellWidth = () => {
        return this.cellLength(constant.floorWidth(), this.gridCol);
    }
    findCell = (row: number, col: number) => {
        if (!(this.cellList && this.cellList.length)) {
            return createCell();
        }
        return this.cellList.find((cell) => cell.row === row && cell.col == col) || createCell();
    }
    getTitle = (row: number, col: number) => {
        return this.findCell(row, col).title;
    }
    show = (row: number, col: number, target: (cell: Cell) => boolean) => {
        const cell = this.findCell(row, col);

        return !cell.match(row, col) || target(cell);
    }
    showTop = (row: number, col: number) => {
        return this.show(row, col, (cell) => cell.top);
    }
    showBottom = (row: number, col: number) => {
        return this.show(row, col, (cell) => cell.bottom);
    }
    showLeft = (row: number, col: number) => {
        return this.show(row, col, (cell) => cell.left);
    }
    showRight = (row: number, col: number) => {
        return this.show(row, col, (cell) => cell.right);
    }

}

const createFloor = (model: Partial<Floor>) => {
    return new Floor(model);
};

export default createFloor;