const createUrl = (path) => {
    return process.env.VUE_APP_API_ORIGIN + path;
};
const MainType = "temp";
const Dig = 1;
const Unknown = "--";
const Gray = "#4d4d4d";
const Green = "#33b43c";
const Yellow = "#f5e214";
const Red = "#ff7219";
const LevelColorDict = {
    0: Gray,
    1: Green,
    2: "#64c032",
    3: "#94cb28",
    4: "#c4d71e",
    5: Yellow,
    6: "#f7cc13",
    7: "#fabc1e",
    8: "#fa9810",
    9: "#fc880e",
    10: Red,
};
const constant = {
    mainType() {
        return MainType;
    },
    searchUnitUnknown() {
        return -1;
    },
    searchUnitSignal() {
        return 1;
    },
    searchUnitRanking() {
        return 2;
    },
    searchUnitMultiple() {
        return 3;
    },
    searchUnitSensor() {
        return 4;
    },
    searchUnitFloor() {
        return 5;
    },
    searchUnitCsv() {
        return 6;
    },
    searchUnitCsvArea() {
        return 7;
    },
    userKindUnknown() {
        return -1;
    },
    userKindUser() {
        return 1;
    },
    userKindStoreManager() {
        return 2;
    },
    userKindAreaManager() {
        return 3;
    },
    intervalMs() {
        return Number(process.env.VUE_APP_RELOAD_INTRERVAL) * 1000;
    },
    lowerDefault() {
        return 0;
    },
    levelDefault() {
        return 0;
    },
    levelMin() {
        return 1;
    },
    levelMax() {
        return 10;
    },
    urlHash(hash) {
        return createUrl("users/hash/?hash=" + hash);
    },
    urlLogin() {
        return createUrl("users/login/");
    },
    urlSummary() {
        return createUrl("summaries/search/");
    },
    urlDownload() {
        return createUrl("downloads/csv/");
    },
    urlDownloadArea() {
        return createUrl("downloads/csv/area/");
    },
    urlDownloadAsync() {
        return createUrl("downloads/csvasync/");
    },
    urlDownloadAreaAsync() {
        return createUrl("downloads/csvasync/area/");
    },
    urlArea() {
        return createUrl("areas/search/");
    },
    storeUserLogin() {
        return "user/login";
    },
    /** 
     * ユーザ情報を取得 (getters)
     * @see {storeUser.getters.user} by store.user.js 
     */
    storeUserGettersUser() {
        return "user/user";
    },
    /** 
     * 検索処理
     * @see {storeSummary.actions.search}  by store.summary.js
     */
    storeSummarySearch() {
        return "summary/search";
    },
    storeSummaryStopLoading() {
        return "summary/stopLoading";
    },
    /** 
     * フロアのSummaryを取得 (getters)
     * @see {storeSummary.getters.summaryFloor} by store.summary.js 
     */
    storeSummaryGettersSummaryFloor() {
        return "summary/summaryFloor";
    },
    /** 
     * センサデータを取得 (getters)
     * @see {storeSummary.getters.sensorData} by store.summary.js 
     */
    storeSummaryGettersSensorData() {
        return "summary/sensorData";
    },

    /** 
     * センサー検索条件:検索日Primary (UnixTime)を取得 (getters)
     * @see {storeSummary.getters.searchDatePrimary} by store.summary.js 
     */
    storeSummaryGettersSearchDatePrimary() {
        return "summary/searchDatePrimary";
    },

    /** 
     * センサー検索条件:検索日Primary (UnixTime)を設定 (setters)
     * @see {storeSummary.actons.setSearchDatePrimary} by store.summary.js 
     */
    storeSummarySetSearchDatePrimary() {
        return "summary/setSearchDatePrimary";
    },

    /** 
     * センサー検索条件:検索日Secondary  (UnixTime)を取得 (getters)
     * @see {storeSummary.getters.searchDateSecondary} by store.summary.js 
     */
    storeSummaryGettersSearchDateSecondary() {
        return "summary/searchDateSecondary";
    },

    /** 
     * センサー検索条件:検索日Secondary  (UnixTime)を設定 (setters)
     * @see {storeSummary.actions.setSearchDateSecondary} by store.summary.js 
     */
    storeSummarySetSearchDateSecondary() {
        return "summary/setSearchDateSecondary";
    },

    /** 
     * センサー検索条件:検索日付間隔を取得 (getters)
     * @see {storeSummary.getters.searchPeriod} by store.summary.js 
     */
    storeSummaryGettersSearchPeriod() {
        return "summary/searchPeriod";
    },

    /** 
     * センサー検索条件:検索日付間隔を設定 (setters)
     * @see {storeSummary.actions.setSearchPeriod} by store.summary.js 
     */
    storeSummarySetSearchPeriod() {
        return "summary/setSearchPeriod";
    },

    /** 
     * センサー検索条件:Secondary検索の有効フラグを取得 (getters)
     * @see {storeSummary.getters.searchSecondaryFlag} by store.summary.js 
     */
    storeSummaryGettersSearchSecondaryFlag() {
        return "summary/searchSecondaryFlag";
    },

    /** 
     * センサー検索条件:Secondary検索の有効フラグを設定 (setters)
     * @see {storeSummary.actions.setSearchSecondaryFlag} by store.summary.js 
     */
    storeSummarySetSearchSecondaryFlag() {
        return "summary/setSearchSecondaryFlag";
    },


    storeDownloadCsv() {
        return "download/csv";
    },
    storeDownloadCsvAsync() {
        return "download/csvasync";
    },
    storeDownloadCsvArea() {
        return "downloadArea/csv";
    },
    storeDownloadCsvAreaAsync() {
        return "downloadArea/csvasync";
    },
    storeAreaSearch() {
        return "area/search";
    },

    /** 
     * CSV情報を取得 (getters)
     * @see {storeDownload.getters.csvinfo} by store.download.js 
     */
    storeDownloadCsvGettersCsvInfo() {
        return "download/csvInfo";
    },
    /** 
     * CSV情報を取得 (getters)
     * @see {storeDownload.getters.csvinfo} by store.download.js 
     */
    storeDownloadAreaCsvGettersCsvInfo() {
        return "downloadArea/csvInfo";
    },
    
    /** 
     * 黄の場合に鳴らす通知音を取得 (getters)
     * @see {storeRing.getters.musicYellow} by store.ring.js 
     */
    storeRingGettersMusicYellow() {
        return "ring/musicYellow";
    },

    /** 
     * 赤の場合に鳴らす通知音を取得 (getters)
     * @see {storeRing.getters.musicRed} by store.ring.js 
     */
    storeRingGettersMusicRed() {
        return "ring/musicRed";
    },

    /** 
     * 通知音を鳴らす全てのセンサの一覧を取得 (getters)
     * @see {storeRing.getters.ringSensorList} by store.ring.js 
     */
    storeRingGettersRingSensorList() {
        return "ring/ringSensorList";
    },

    /** 
     * センサが通知音をならす設定かつお休み時間帯以外か確認 (getters)
     * @see {storeRing.getters.isRingSensor} by store.ring.js 
     */
    storeRingGettersIsRingSensorAtTimer() {
        return "ring/isRingSensorAtTime";
    },

    /** 
     * センサが通知音をならす設定か確認 (getters)
     * @see {storeRing.getters.isRingSensor} by store.ring.js 
     */
    storeRingGettersIsRingSensor() {
        return "ring/isRingSensor";
    },

    /** 
     * センサが通知音をならす設定かつお休み時間帯か確認 (getters)
     * @see {storeRing.getters.isDoNotDisturbSensor} by store.ring.js 
     */
    storeRingGettersIsDoNotDisturbSensor() {
        return "ring/isDoNotDisturbSensor";
    },

    /** 
     * ブラウザが通知音をならす状態になっているか確認 (getters)
     * @see {storeRing.getters.isEnableRing} by store.ring.js 
     */
    storeRingGettersIsEnableRing() {
        return "ring/isEnableRing";
    },

    /** 
     *  全てのセンサの通知音設定一覧を取得 (getters)
     * @see {storeRing.getters.sensorRingInfoList} by store.ring.js 
     */
    storeRingGettersSensorRingInfoList() {
        return "ring/sensorRingInfoList";
    },

    /** 
     * 黄の場合に鳴らす通知音を設定
     * @see {storeRing.mutations.setMusicYellow} by store.ring.js 
     */
    storeRingSetMusicYellow() {
        return "ring/setMusicYellow";
    },

    /** 
     * 赤の場合に鳴らす通知音を設定
     * @see {storeRing.mutations.setMusicRed} by store.ring.js 
     */
    storeRingSetMusicRed() {
        return "ring/setMusicRed";
    },

    /** 
     * 通知音の設定をLocalStorageから読み込み
     * @see {storeRing.mutations.readRingSensorListByLocalStorage} by store.ring.js 
     */
    storeRingReadRingSensorListByLocalStorage() {
        return "ring/readRingSensorListByLocalStorage";
    },

    /** 
     * 全てのセンサの通知音設定一覧をLocalStorageから読み込み
     * @see {storeRing.mutations.readSensorRingInfoByLocalStorage} by store.ring.js 
     */
    storeRingReadSensorRingInfoByLocalStorage() {
        return "ring/readSensorRingInfoByLocalStorage";
    },


    /** 
     * センサを通知音を鳴らす設定に変更
     * @see {storeRing.mutations.addRingSensor} by store.ring.js 
     */
    storeRingAddRingSensor() {
        return "ring/addRingSensor";
    },

    /** 
     * センサを通知音を鳴らさない設定に変更
     * @see {storeRing.mutations.deleteRingSensor} by store.ring.js 
     */
    storeRingDeleteRingSensor() {
        return "ring/deleteRingSensor";
    },

    /** 
     * 通知音を鳴らすセンサを一括で全て設定を変更
     * @see {storeRing.mutations.setAllRingSensor} by store.ring.js 
     */
    storeRingSetAllRingSensor() {
        return "ring/setAllRingSensor";
    },

    /** 
     * 全てのセンサを通知音を鳴らさない設定に変更
     * @see {storeRing.mutations.deleteAllRingSensor} by store.ring.js 
     */
    storeRingDeleteAllRingSensor() {
        return "ring/deleteAllRingSensor";
    },

    // ToDo: Cookie処理からLocalStorage処理に書き換えたため不要。あとで削除。
    /** 音を鳴らすセンサー一覧を格納するCookie名 */
    ringSensorListCookieName() {
        return "ringSensorList";
    },
    // ToDo: Cookie処理からLocalStorage処理に書き換えたため不要。あとで削除。
    /** 音を鳴らすセンサー一覧を格納するCookieの保持期間 */
    ringSensorListCookieMaxDay() {
        return 360;
    },

    /** 音を鳴らすセンサー一覧を格納するlocalStorage名 */
    ringSensorListLocalStorageName() {
        return "air_ringSensorList";
    },

    /** 
     * 全てのセンサの通知音設定を変更
     * @see {storeRing.mutations.setAllSensorRingInfo} by store.ring.js 
     */
    storeSetAllSensorRingInfo() {
        return "ring/setAllSensorRingInfo";
    },

    // ToDo: Cookie処理からLocalStorage処理に書き換えたため不要。あとで削除。
    /** 全てのセンサの通知音設定一覧を格納するCookie名 */
    sensorRingInfoListCookieName() {
        return "sensorRingInfoList";
    },

    /** 全てのセンサの通知音設定一覧を格納するlocalStorage名 */
    sensorRingInfoListLocalStorageName() {
        return "air_sensorRingInfoList";
    },

    floorHeight() {
        return 78;
    },
    floorWidth() {
        return 90;
    },
    defaultDoNotDisturbTimeStart() {
        return "21:00";
    },
    defaultDoNotDisturbTimeEnd() {
        // HH:mm
        return "09:00";
    },
    getChartColor(level) {
        return (level in LevelColorDict) ? LevelColorDict[level] : this.gray();
    },
    src(image) {
        return require("../assets/" + image + ".png");
    },
    isNumber(val) {
        return /^\d*$/.test(val);
    },
    isInteger(val) {
        return /^[-]?([1-9]\d*|0)$/.test(val);
    },
    isDecimal(val) {
        return /^[-]?([1-9]\d*|0)(\.\d+)?$/.test(val);
    },
    floorMaxFontSize() {
        return 1.8;
    },
    getFontSize(title, width, max) {
        const len = title ? title.length : 1;
        const ret = width / len;

        return ret > max ? max : ret;
    },
    jst() {
        return (9 * 60 * 60 * 1000);
    },
    toUnixTime(date) {
        const ms = date ? date.getTime ? date.getTime() : date : 0;

        return Math.floor((ms - this.jst()) / 1000);
    },
    toDate(unixtime) {
        return new Date(unixtime * 1000 + this.jst());
    },
    today() {
        const ret = new Date();

        return new Date(ret.getFullYear(), ret.getMonth(), ret.getDate(), 9);
    },
    /** 
     * toLocaleStringのオプションで使うとYMD表記。toLocaleString("ja",constant.toLocaleStringYmdOptions())のように使う。
    */
    toLocaleStringYmdOptions() {
        return {
            "hour12": false,
            "year": "numeric",
            "month": "2-digit",
            "day": "2-digit"
        };
    },
    /** 
     * toLocaleStringのオプションで使うとYMD表記。toLocaleString("ja",constant.toLocaleStringDateTimeOptions())のように使う。
    */
    toLocaleStringDateTimeOptions() {
        return {
            "hour12": false,
            "year": "numeric",
            "month": "2-digit",
            "day": "2-digit",
            "hour": "2-digit",
            "minute": "2-digit",
            "second": "2-digit"
        };
    },
    totime(unixtime: number) {
        const ret = new Date(unixtime * 1000);
        const hour = ret.getHours().toString().padStart(2, '0');
        const minute = ret.getMinutes().toString().padStart(2, '0');
        return hour + ":" + minute;
    },
    getNowUnixTime() {
        const ret = Date.now();
        return Math.floor(ret / 1000);
    },
    getNormalizeUnixtime(unixtime) {
        const datetime = new Date(unixtime * 1000);
        const normalizeUnixtime = new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate(), 0).getTime();

        return Math.floor(normalizeUnixtime / 1000);
    },
    searchPeriodDefault() {
        return 1;
    },
    partitionPrimary() {
        return 1;
    },
    partitionSecondary() {
        return 2;
    },
    dispCo2c(detail) {
        return (detail.isNotEmpty() && this.isInteger(detail.co2c) ? detail.co2c : Unknown) + "ppm";
    },
    dispTemp(detail) {
        return (detail.isNotEmpty() && this.isDecimal(detail.temp) ? detail.temp.toFixed(Dig) : Unknown) + "℃";
    },
    dispHumi(detail) {
        return (detail.isNotEmpty() && this.isDecimal(detail.humi) ? detail.humi.toFixed(Dig) : Unknown) + "%";
    },
    gray() {
        return Gray;
    },
    green() {
        return Green;
    },
    yellow() {
        return Yellow;
    },
    red() {
        return Red;
    },
    // summary.detailのerrorReasonの値
    grayNone() {
        return "none";
    },
    grayOverflow() {
        return "overflow";
    },
    /** Area画面 色を変える際の閾値となる% */
    colorThreshold() {
        return 0.01;
    },
    isUser(user) {
        return !user || user.userKind === this.userKindUser();
    },
    /**
     * @global
     * @typedef SearchPeriod 検索日付間隔
     * @property {string} text 表示テキスト
     * @property {number} value 検索日付間隔の日数
     */
    /**
     * 検索日付間隔 の作成
     * @param {number} value 検索日付間隔の日数
     * @returns {SearchPeriod}
     */
    createSearchPeriod(value) {
        return { text: "直近" + value + "日", value: value };
    },
    createSearchPeriodDefault() {
        return this.createSearchPeriod(this.searchPeriodDefault());
    },
    createSearchPeriodList() {
        return [
            this.createSearchPeriodDefault(),
            this.createSearchPeriod(2),
            this.createSearchPeriod(7),
            this.createSearchPeriod(30),
            this.createSearchPeriod(60),
            this.createSearchPeriod(90),
        ];
    },
    getQuery(airoco) {
        const ret = {};

        for (const key in airoco) {
            if (airoco[key]) {
                ret[key] = airoco[key];
            }
        }

        return ret;
    },
    setQuery(airoco, route) {
        airoco.hash = route.query.hash || "";
    },
    screenList() {
        return "list";
    },
    screenRanking() {
        return "ranking";
    },
    screenFloor() {
        return "floor";
    },
    screenSensor() {
        return "sensor";
    },
    screenArea() {
        return "area";
    },
    pointPercentHeight() {
        return 0.70;
    },
    pointPercentWidth() {
        return 0.96;
    },
    getLength(name) {
        let length = 0;
        for (let i = 0; i < name.length; i++) {
            if (name[i].match(/[ -~]/)) {
                length += 0.5;
            } else {
                length += 1;
            }
        }
        return length;
    },
    getMain(type) {
        let no;
        if (type == "co2c") {
            no = 0;
        } else if (type == "temp") {
            no = 1;
        } else if (type == "humi") {
            no = 2;
        }
        return no;
    },
    /**
     * scriptから画像指定したとき
     */
    setImage(name) {
        return require('../assets/' + name);
    },
    /**
     * Sleep 
     * @param milliseconds 
     * @returns 
     */
    sleepAsync(milliseconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    },
};

/*---- 以下、型定義 ----*/

/** StyleにBindする型 */
export type StyleType = string | object[] | { [key: string]: string; };

export default constant;