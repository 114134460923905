import Vue from "vue";
import constant, { StyleType } from "./constant";
import models from "./models";
import store from "../store";

Vue.prototype.$airoco = {
    hash: "",
};

const mixinApp = {
    props: {
        propUser: null,
        propSummary: null,
        propArea: null,        
    },
    data() {
        return {
            unsubscribe: () => { },
            donePost: false,
            //user: this.propUser,
            summary: this.propSummary,
            innerHeight: window.innerHeight,
            innerWidth: window.innerWidth,
            interval: null,
            isIphone: false,
            isIphoneByWidth: false,
            isSPByWidth: false,
        };
    },
    computed: {
        bindStyle(): StyleType {
            return {
                "--main-background-color": this.getMainBackgroundColor(),
                "--sub-background-color": this.getSubBackgroundColor(),
                "--grid-background-color": this.getGridBackgroundColor(),
                "--card-background-color": this.getMainBackgroundColor(),
                "--chart-area-card-height": this.innerHeightCriteria * 75 + "px",
                "--card-font-color": "white",
                "--card-border-style": "solid",
                "--card-border-width": 5 + "px",
                "--card-border-radius": 0.5 + "em",
                "--card-span-transform": "scale(1, 1.25)",
            };
        },
        hierarchy() {
            return this.user ? this.user.getCurrentHierarchy() : models.createHierarchy();
        },
        strategy() {
            return this.user && this.user.strategy ? this.user.strategy : models.createStrategy();
        },
        loading() {
            return !(this.user && this.summary);
        },
        innerHeightCriteria() {
            return this.innerHeight * 0.01;
        },
        innerWidthCriteria() {
            return this.innerWidth * 0.01;
        },
        carouselHeightPx() {
            return this.innerHeightCriteria * 90 + "px";
        },
        isLandscape() {
            return this.innerWidth >= this.innerHeight;
        },
        /** 黄色の通知音 */
        musicYellow() {
            return store.getters[constant.storeRingGettersMusicYellow()];
        },
        /** 赤色の通知音 */
        musicRed() {
            return store.getters[constant.storeRingGettersMusicRed()];
        },
        /** 通知音を鳴らすSensor Number一覧 */
        ringSensorList() {
            return store.getters[constant.storeRingGettersRingSensorList()];
        },
        /** 全てのセンサの通知音設定一覧を取得 */
        sensorRingInfoList() {
            return store.getters[constant.storeRingGettersSensorRingInfoList()];
        },
        /** ブラウザが通知音を鳴らす状態になっているか */
        isEnableRing() {
            return store.getters[constant.storeRingGettersIsEnableRing()];
        },

        /**
         * 全てのセンサが通知音をならす設定か確認
         * @returns 全てのセンサが通知音をならす場合、true
         */
        isRingSensorListAll() {
            return this.ringSensorList.length == this.sensorNumberList.length;
        },
        /**
         * 全てのセンサが通知音をならさない設定か確認
         * @returns 全てのセンサが通知音をならさない場合、true
         */
        isRingSensorListEmpty() {
            return this.ringSensorList == null || this.ringSensorList.length == 0;
        },
        /** ユーザ情報 */
        user() {
            return store.getters[constant.storeUserGettersUser()];
        },
        /** フロア情報 */
        summaryFloor() {
            return store.getters[constant.storeSummaryGettersSummaryFloor()];
        },
        /** Sensor Number 一覧 */
        sensorNumberList() {
            return this.summaryFloor.detailList.map(x => (x.sensor.sensorNumber));
        },
        /** Sensor 一覧 */
        sensorList() {
            return this.summaryFloor.detailList.map(x => (x.sensor));
        },
        /** 表示可能な詳細一覧 */
        displayableDetailList(){
            return this.user.isUser() ? this.summaryFloor.detailList.filter((x) => x.sensor.enableToUser) : this.summaryFloor.detailList;
        },
    },
    methods: {
        resize() {
            this.innerHeight = window.innerHeight;
            this.innerWidth = window.innerWidth;
            this.checkIphone();
            this.checkIphoneByWidth();
            this.checkSPByWidth();

            if (typeof (this.afterResize) === "function") {
                this.afterResize();
            }
        },
        getQuery() {
            return constant.getQuery(this.$airoco);
        },
        setQuery() {
            constant.setQuery(this.$airoco, this.$route);
        },
        clearUserSummary() {
            this.user = models.createUser();
            this.setSummary(models.createSummary());
        },
        getSummary() {
            return this.summary;
        },
        setSummary(summary) {
            this.summary = Object.assign({}, summary);
        },
        /**
         * センサーデータの取得
         * @param {string} sensorNumber 
         * @returns センサーデータ
         */
         getSensorData(sensorNumber){
            return store.getters["summary/sensorData"](sensorNumber)
        },
        /**
         * 詳細の取得
         * @param {*} sensorNumber 
         * @returns 
         */
        getDetail(sensorNumber){
            const detail = this.displayableDetailList.find(x => x.sensor.sensorNumber == sensorNumber);
            return detail ? detail : models.createDetail();
        },
        startInterval(createSearchCondition) {
            if (this.interval) {
                return;
            }

            this.interval = setInterval(
                (
                    () => {
                        store.dispatch(constant.storeSummarySearch(), createSearchCondition());
                    }).bind(this),
                constant.intervalMs()
            );
        },
        stopInterval() {
            clearInterval(this.interval);
            this.interval = null;
        },
        getMainBackgroundColor() {
            return "#202020";
        },
        getSubBackgroundColor() {
            return "#181818";
        },
        getGridBackgroundColor() {
            return "#666666";
        },
        searchSummary() {
            if (this.summary) {
                this.startInterval(this.createSearchCondition);

                if (this.donePost) {
                    return;
                }

                this.donePost = true;
                this.postSearchSummary();
            } else {
                store.dispatch(constant.storeSummarySearch(), this.createSearchCondition());
            }
        },
        postSearchSummary() {
            return;
        },
        autoUpdating() {
            if (!this.user || this.user.unauthorized()) {
                this.transitionRoot();
            } else {
                this.searchSummary();
            }

            this.unsubscribe = store
                .subscribe((mutation, state: any) => {
                    if (mutation.type === constant.storeSummarySearch()) {
                        const summary = state.summary.summary;

                        if (summary.condition.isSearchUnitSensor()) {
                            this.setSummarySensor(summary);
                        } else {
                            // 音の鳴る仕組み
                            // 音を鳴らすセンサ && 現在時刻がお休み時間以外のsensorNumberを抽出する
                            const nowTimeValue = Number(constant.totime(constant.getNowUnixTime()).replace(':', ''));
                            const ringList = this.sensorRingInfoList
                                .filter(x => this.ringSensorList.includes(x.sensorNumber))
                                .filter(x => {
                                    if (x.enableDoNotDisturb) {
                                        // お休みモードONの場合、現在時刻がお休み時間外であることを条件に追加
                                        const doNotDisturbTimeStartValue = Number(x.doNotDisturbTimeStart.replace(':', ''));
                                        const doNotDisturbTimeEndValue = Number(x.doNotDisturbTimeEnd.replace(':', ''));
                                        if (doNotDisturbTimeStartValue < doNotDisturbTimeEndValue) {
                                            // start < end なら start<--->2400 と 0<--->end
                                            return (nowTimeValue < doNotDisturbTimeStartValue || nowTimeValue > doNotDisturbTimeEndValue);
                                        } else {
                                            // start >= end なら end<-->start
                                            return (nowTimeValue < doNotDisturbTimeStartValue && nowTimeValue > doNotDisturbTimeEndValue);
                                        }
                                    } else {
                                        // お休みモードOFFの場合
                                        return true;
                                    }
                                })
                                .map(x => x.sensorNumber);

                            // 音を鳴らす設定のセンサリスト(filteredByRingList)を作成
                            const filteredByRingList = summary.detailList
                                .filter(x => (ringList.includes(x.sensor.sensorNumber)))
                                .map(x => ({ sensorNumber: x.sensor.sensorNumber, co2c: x.co2c }));

                            //閾値ごとに鳴らす音を変える(更新ごとに毎回鳴らす)
                            const ringYellow = filteredByRingList
                                .some(x => (x.co2c >= this.hierarchy.lowerYellow));
                            const ringRed = filteredByRingList
                                .some(x => (x.co2c >= this.hierarchy.lowerRed));
                            if (ringRed) {
                                // Co2閾値赤の時に音を鳴らす
                                const music = this.musicRed;
                                if (music) music.play();
                            }
                            else if (ringYellow) {
                                // Co2閾値黄の時に音を鳴らす
                                const music = this.musicYellow;
                                if (music) music.play();
                            }

                            this.setSummary(summary);
                            this.searchSummary();
                        }
                    }
                })
                .bind(this);
        },
        setSummarySensor(summary) {
            summary.toString();
            return;
        },
        transitionRoot() {
            this.setQuery();
            this.$router.push({ name: "root", query: this.getQuery(), });
        },
        transitionArea() {
            this.user.setCurrentHierarchy(this.user.hierarchy);
            this.$router.push({
                name: "area",
                params: {
                    "propUser": this.user,
                    "propArea": this.propArea,
                },
                query: this.getQuery(),
            });
        },
        getLoading() {
            return this.loading;
        },
        checkIphone() {
            if (window.innerHeight < 430) {
                return this.isIphone = true;
            }
            return this.isIphone = false;
        },
        checkIphoneByWidth() {
            if (window.innerWidth < 600) {
                return this.isIphoneByWidth = true;
            }
            return this.isIphoneByWidth = false;
        },
        checkSPByWidth() {
            //Memo:温湿度有効時には、要見直し
            // if (window.innerWidth < 1200) {
                return this.isSPByWidth = true;
            // }
            // return this.isSPByWidth = false;
        },
        /**
         * 通知音の設定をLocalStorageから読み込み
         */
        readRingSensorListByLocalStorage() {
            store.dispatch(constant.storeRingReadRingSensorListByLocalStorage());
        },

        /**
         * 全てのセンサの通知音設定一覧をLocalStorageから読み込み
         */
        readSensorRingInfoByLocalStorage() {
            store.dispatch(constant.storeRingReadSensorRingInfoByLocalStorage());
        },


        /**
         * センサを通知音を鳴らす設定に変更
         * @param {string} sensorNumber 通知音を鳴らすセンサ
         */
        addRingSensor(sensorNumber) {
            store.dispatch(constant.storeRingAddRingSensor(), sensorNumber);
        },
        /**
         * センサを通知音を鳴らさない設定に変更
         * @param {string} sensorNumber 通知音を鳴らさないセンサ
         */
        deleteRingSensor(sensorNumber) {
            store.dispatch(constant.storeRingDeleteRingSensor(), sensorNumber);
        },
        /**
         * 全てのセンサを通知音を鳴らす設定に変更
         */
        addAllRingSensor() {
            //全て追加
            store.dispatch(constant.storeRingSetAllRingSensor(), this.sensorNumberList);
        },
        /**
         * 全てのセンサを通知音を鳴らさない設定に変更
         */
        deleteAllRingSensor() {
            store.dispatch(constant.storeRingDeleteAllRingSensor());
        },
        /**
         * 指定のセンサ(複数可)のみを通知音を鳴らす設定に変更
         */
        changeAllRingSensor(sensorNumberList) {
            //一括変更
            store.dispatch(constant.storeRingSetAllRingSensor(), sensorNumberList);
        },

        /**
         * 全てのセンサの通知音設定を変更
         */
        changeAllSensorRingInfo(sensorRingInfoList) {
            //一括変更
            store.dispatch(constant.storeSetAllSensorRingInfo(), sensorRingInfoList);
        },

        /**
         * センサが通知音をならす設定かつお休み時間帯以外か確認
         * @param {string} sensorNumber 通知音を確認したいセンサ
         * @returns 通知音をならすかつお休み時間帯以外の場合、true
         */
        isRingSensorAtTime(sensorNumber) {
            return store.getters[constant.storeRingGettersIsRingSensorAtTimer()](sensorNumber);
        },

        /**
         * センサが通知音をならす設定か確認
         * @param {string} sensorNumber 通知音を確認したいセンサ
         * @returns 通知音をならす場合、true
         */
        isRingSensor(sensorNumber) {
            return store.getters[constant.storeRingGettersIsRingSensor()](sensorNumber);
        },

        /**
         * センサが通知音をならす設定かつお休み時間帯か確認
         * @param {string} sensorNumber 通知音設定を確認したいセンサ
         * @returns 通知音をならすかつお休み時間帯の場合、true
         */
        isDoNotDisturbSensor(sensorNumber) {
            return store.getters[constant.storeRingGettersIsDoNotDisturbSensor()](sensorNumber);
        },
        /** 
         * 通知用音楽の読み込み (Storeへの登録、iOSを考慮して再生＋停止でうまく再生できない場合登録しない)
         * @see {storeRing.getters.musicYellow} by store.ring.js 
         */
        importRingMusic() {
            store.dispatch("ring/importRingMusic");
        },
        /**
         * 黄色用通知音の再生(再生できない場合は何もしない)
         */
        playMusicYellow() {
            const music = this.musicYellow;
            if (music) {
                music.play();
            } else {
                console.log("音声を再生できません。ボタンを押して再生できるようにしてください。");
            }
        },
        /**
         * 赤色用通知音の再生(再生できない場合は何もしない)
         */
        playMusicRed() {
            const music = this.musicRed;
            if (music) {
                music.play();
            } else {
                console.log("音声を再生できません。ボタンを押して再生できるようにしてください。");
            }
        },
    },
    mounted() {
        this.checkIphone();
        this.checkIphoneByWidth();
        this.checkSPByWidth();
        window.addEventListener("resize", this.resize);
    },
    beforeDestroy() {
        this.stopInterval();
        this.unsubscribe();
        window.removeEventListener("resize", this.resize);
    },
};

export default mixinApp;