import axios from "axios";
import constant from "./constant";
import { Summary } from "./model.summary";
import models from "./models";

const storeAreaState = {
    area: null as any
};
type StoreArea = typeof storeAreaState;

const storeArea = {
    namespaced: true,
    state: storeAreaState,
    mutations: {
        search(state: StoreArea, result: any) {
            state.area = models.createArea(result);
        },
    },
    actions: {
        search({ commit }: { commit: any }, condition: any) {
            axios.post(constant.urlArea(), condition, models.createRequestConfig()).then((response) => {
                commit("search", response.data);
            }).catch(err => alert(err.toString()));
        },
    },
};

export default storeArea;