import createAreaSearchCondition, { AreaSearchCondition } from "./model.area.search.condition";
import { Hierarchy } from "./model.hierarchy";
import createPoint from "./model.point";
import { Sensor } from "./model.sensor";

export class Area {
    public condition: AreaSearchCondition;
    public pointList: any[];
    public currentSensor: Sensor;
    public currentHierarchy: Hierarchy;

    constructor(init?: Partial<Area>) {
        this.condition = init?.condition ? createAreaSearchCondition(init.condition) : createAreaSearchCondition(null);
        this.pointList = init?.pointList ?
            init.pointList.map(point => createPoint(point)) : [];

    }

    count = () => {
        return this.pointList.length;
    }

    // 渡された横幅に応じて1行当たりの最大拠点数を変動させる
    getGrid = (displaywidth: number) => {
        const baseMin = 3;
        let baseMax = 6;
        switch(true)
        {
            case displaywidth < 750:
                baseMax = 3;
                break;
                case displaywidth < 900:
                    baseMax = 4;
                        break;
                    case displaywidth < 1100:
                        baseMax = 5;
                        break;
                        default:
                            baseMax = 6;
                            break;
        }
        const getGrid = (base, count) => {
            return Math.pow(base, 2) < count ? 
                base>=baseMax ? 
                baseMax : 
                    getGrid(base + 1, count) 
                    : 
                base;
        };

        return getGrid(baseMin, this.count());
    }

    getPointCount = () => {
        const baseMin = 3;
        const getGrid = (base, count) => {
            return Math.pow(base, 2) < count ?
                getGrid(base + 1, count) 
                : 
                base;
        };

        return getGrid(baseMin, this.count());
    }

    getIndex = (i: number, j: number, colMax: number) => {
        return (i - 1) * colMax + (j - 1);
    }

    getPoint = (i: number, j: number, colMax: number) => {
        return this.getIndex(i, j, colMax) < this.count() ? this.pointList[this.getIndex(i, j, colMax)] : createPoint();
    }

    getMaxWeightTotal = () => {
        return Math.max(...this.pointList.map((p) => p.getWeightTotal()));
    }
    getMaxSensorCount = () => {
        return Math.max(...this.pointList.map((p) => p.countSensor));
    }
    sortPointListBySortOrder = () => {
        this.pointList.sort((a, b) => {
            return a.hierarchyCode < b.hierarchyCode ? -1 : 1;
        });
    }

    sortPointListByGeCo2c = (hierarchy: Hierarchy, geCo2c: number) => {
        this.pointList.sort((a, b) => {
            let counta = a.countRed;
            let countb = b.countRed;

            if (hierarchy.lowerYellow >= geCo2c) {
                counta += a.countYellow;
                countb += b.countYellow;
            }

            if (hierarchy.lowerGreen >= geCo2c) {
                counta += a.countGreen;
                countb += b.countGreen;
            }

            return b.getWeight(countb) - a.getWeight(counta);
        });
    }

    sort = () => {
        this.sortPointListBySortOrder();
        this.pointList.forEach((point) => point.sort());
    }

    countMax = (hierarchy: Hierarchy) => {
        const min = 5;
        const max = 10;
        const countList = this.pointList.map((p) => p.count(this.condition, hierarchy));
        const val = Math.max(...countList);

        return val < min ? min : val > max ? max : val;
    }

    initVisibleTooltip = () => {
        this.pointList.forEach((p) => p.initVisibleTooltip());
    }
}

const createArea = (model: Area) => {
    return new Area(model);
}
/*
const createArea = (model) => {
    const newModel = () => {
        return {
            condition: null,
            pointList: [],
        };
    };
    const ret = model || newModel();
    const modelList = [];

    for (const point of ret.pointList) {
        modelList.push(createPoint(point));
    }

    ret.condition = createAreaSearchCondition(ret.condition);
    ret.pointList = modelList;
    ret.currentSensor = null;
    ret.currentHierarchy = null;
    ret.count = () => {
        return ret.pointList.length;
    };
    ret.getGrid = () => {
        const baseMin = 3;
        const getGrid = (base, count) => {
            return Math.pow(base, 2) < count ? getGrid(base + 1, count) : base;
        };

        return getGrid(baseMin, ret.count());
    };
    ret.getIndex = (i, j) => {
        return (i - 1) * ret.getGrid() + (j - 1);
    };
    ret.getPoint = (i, j) => {
        return ret.getIndex(i, j) < ret.count() ? ret.pointList[ret.getIndex(i, j)] : createPoint();
    };
    ret.getMaxWeightTotal = () => {
        return Math.max(...ret.pointList.map((p) => p.getWeightTotal()));
    };
    ret.sortPointListBySortOrder = () => {
        ret.pointList.sort((a, b) => {
            return a.hierarchyCode < b.hierarchyCode ? -1 : 1;
        });
    };
    ret.sortPointListByGeCo2c = (hierarchy, geCo2c) => {
        ret.pointList.sort((a, b) => {
            let counta = a.countRed;
            let countb = b.countRed;

            if (hierarchy.lowerYellow >= geCo2c) {
                counta += a.countYellow;
                countb += b.countYellow;
            }

            if (hierarchy.lowerGreen >= geCo2c) {
                counta += a.countGreen;
                countb += b.countGreen;
            }

            return b.getWeight(countb) - a.getWeight(counta);
        });
    };
    ret.sort = () => {
        ret.sortPointListBySortOrder();
        ret.pointList.forEach((point) => point.sort());
    };
    ret.countMax = (hierarchy) => {
        const min = 5;
        const max = 10;
        const countList = ret.pointList.map((p) => p.count(ret.condition, hierarchy));
        const val = Math.max(...countList);

        return val < min ? min : val > max ? max : val;
    };
    ret.initVisibleTooltip = () => {
        ret.pointList.forEach((p) => p.initVisibleTooltip());
    };

    return ret;
};
*/
export default createArea;