import constant, { StyleType } from "./constant";
import { StrategyUser } from "./model.strategy.user";
import { StrategyManager } from "./model.strategy.manager";
import { User } from "./model.user";


const createStrategy = (user?: Partial<User>) => {
	return constant.isUser(user) ? new StrategyUser(user) : new StrategyManager(user);
};

export default createStrategy;