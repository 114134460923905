<template>
    <v-dialog v-model="show" persistent dark width="600px">
        <v-card class="dialog-card">
            <div class="dialog-header">
                <span>{{ title }}</span>
            </div>
            <div class="dialog-content">
                <span>{{ message }}</span>
            </div>
            <div class="dialog-footer">
                <v-btn
                    class="dialog-button"
                    color="transparent"
                    tile
                    large
                    @click="close()"
                >
                    <v-icon color="white" large> mdi-check </v-icon>
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
const vm = {
    props: {
        propGetTitle: null,
        propGetMessage: null,
    },
    data() {
        return {
            show: false,
        };
    },
    computed: {
        title() {
            return this.propGetTitle();
        },
        message() {
            return this.propGetMessage();
        },
    },
    methods: {
        close() {
            this.show = false;
        },
    },
};

export default vm;
</script>
