import constant from "./constant";
import createSummary, { Summary } from "./model.summary";
import createChartData from "./model.chart.data";
import { User } from "./model.user";

/** データの区分 */
type DataType = "co2c"|"temp"|"humi";

/** 
 * センサー用サマリ 
 * サマリをprimaryとsecondaryで保持する
 */
export class SummarySensor {
    public primary: Summary;
    public secondary: Summary;

    constructor(primary?: Partial<Summary>, secondary?: Partial<Summary>) {
        this.primary = createSummary(primary);
        this.secondary = createSummary(secondary);
    }
    
    isNotEmpty = () => {
        return this.primary && this.primary.isNotEmpty();
    }

    isEmpty = () => {
        return !this.isNotEmpty();
    }

    isNotEmptySecondary = () => {
        return this.secondary && this.secondary.isNotEmpty();
    }

    isEmptySecondary = () => {
        return !this.isNotEmptySecondary();
    }

    clearPrimary = () => {
        this.primary = createSummary();
    }

    clearSecondary = () => {
        this.secondary = createSummary();
    }

    setSummary = (summary: Summary) => {
        console.log("SummarySensor setSummary",summary, this);
        if (!summary) {
            return;
        }

        if (summary.condition.isPrimary()) {
            this.primary = summary;
        } else {
            this.secondary = summary;
        }
    }

    formatTimestamp = (timestamp: number | string) => {
        if (!timestamp) {
            return null;
        }
        const timestampValue = typeof timestamp === "string" ? parseInt(timestamp) : timestamp;
        if (isNaN(timestampValue)) {
            return null;
        }

        const isDateDisplay = this.primary.condition.searchPeriod > 2;
        const iso = constant.toDate(timestampValue).toISOString();
        const isoDate = iso.substring(5, 10);
        const isoTime = iso.substring(11, 16);

        return isDateDisplay ? isoDate : isoTime;
    }

    getTempColor = (value :number) =>{
        if (value > 30) { return "#E6655C"; }
        else if (value > 29) { return "#E0875E"; }
        else if (value > 28) { return "#DBA55E"; }
        else if (value > 27) { return "#D4BE5F"; }
        else if (value > 26) { return "#C7CF5F"; }
        else if (value > 25) { return "#A8C961"; }
        else if (value > 24) { return "#8CC460"; }
        else if (value > 23) { return "#71BD60"; }
        else if (value > 22) { return "#61B867"; }
        else if (value > 21) { return "#60BD7E"; }
        else if (value > 20) { return "#60C499"; }
        else if (value > 19) { return "#61C9B6"; }
        else if (value > 18) { return "#5FC7CF"; }
        else if (value > 17) { return "#5FAFD4"; }
        else if (value > 16) { return "#5E94DB"; }
        else if (value > 15) { return "#5E76E0"; }
        else { return "#655DD9"; }
    }
    getHumiColor = (value :number) =>{
        if (value > 70) { return "#655DD9"; }
        else if (value > 67.5) { return "#5E76E0"; }
        else if (value > 65) { return "#5E94DB"; }
        else if (value > 62.5) { return "#5FAFD4"; }
        else if (value > 60) { return "#5FC7CF"; }
        else if (value > 57.5) { return "#61C9B6"; }
        else if (value > 55) { return "#60C499"; }
        else if (value > 52.5) { return "#60BD7E"; }
        else if (value > 50) { return "#61B867"; }
        else if (value > 47.5) { return "#71BD60"; }
        else if (value > 45) { return "#8CC460"; }
        else if (value > 42.5) { return "#A8C961"; }
        else if (value > 40) { return "#C7CF5F"; }
        else if (value > 37.5) { return "#D4BE5F"; }
        else { return "#DBA55E"; }
    }    

    setListData = (list, user: User, summary: Summary, dataType: DataType) => {
        if (summary.isEmpty()) {
            return;
        }

        const chartDataDict = summary.condition.initSensorChartDataDict();

        for (const detail of summary.detailList) {
            if (!detail || detail.isEmpty()) {
                continue;
            }

            const key = detail.timestamp;

            
            if (chartDataDict[key]) {
                const value = 
                    dataType == "temp" ?
                        /* 温度 */
                        detail.temp :
                        dataType == "humi" ?
                            /* 湿度 */
                            detail.humi :
                            /* CO2 */
                            detail.co2c
                            ;
                const color = 
                    dataType == "temp" ?
                        this.getTempColor(detail.temp) :
                        dataType == "humi" ?
                        this.getHumiColor(detail.humi) :
                            user.strategy.getChartColor(detail)
                            ;                            
                chartDataDict[key] = createChartData(value, color, detail);
                //Memo:温湿度有効時には、要見直し
            }
        }

        const chartDataList = Object.values(chartDataDict);

        chartDataList.forEach((value, index) => {
            const indexPrev = index - 1;
            const indexNext = index + 1;
            const prev = indexPrev >= 0 ? chartDataList[indexPrev] : createChartData();
            const next = indexNext < chartDataList.length ? chartDataList[indexNext] : createChartData();

            list[index] = list[index].concat(value.data(prev.isEmpty() && next.isEmpty()));
        });
    }

    getSensorChartDataList = (user: User, dataType: DataType) => {
        const joinHeader = (valueList?) => {
            const header = this.secondary.isNotEmpty() ? 
            [
                "timestamp",
                { label: "primary", type: 'number' }, { role: "style" }, { role: "tooltip" },
                { label: "secondary", type: 'number' }, { role: "style" }, { role: "tooltip" }
            ]:
            [
                "timestamp",
                { label: "primary", type: 'number' }, { role: "style" }, { role: "tooltip" }
            ];
            return valueList ? [header].concat(valueList) : [header];
        };

        if (this.isEmpty()) {
            return joinHeader();
        }

        const resultDict = {};
        const primaryChartDataDict = this.primary.condition.initSensorChartDataDict();
        const primaryKeyList = Object.keys(primaryChartDataDict);
        const labelInterval = primaryKeyList.length / 8;

        primaryKeyList.forEach((key, index) => {
            const timestamp = index && (index % labelInterval) === 0 ? this.formatTimestamp(key) : null;

            resultDict[key] = [timestamp];
        });

        const resultList = Object.values(resultDict);

        this.setListData(resultList, user, this.primary, dataType);
        this.setListData(resultList, user, this.secondary, dataType);

        return joinHeader(resultList);
    }
}

/** センサー用サマリ作成 */
const createSummarySensor = (primary?: Summary, secondary?: Summary) => {
    return new SummarySensor(primary, secondary);
};

// const createSummarySensor = (primary, secondary) => {

//     const newmodel = () => {
//         return {
//             primary: createSummary(primary),
//             secondary: createSummary(secondary),
//         };
//     };
//     const ret = newmodel();

//     ret.isNotEmpty = () => {
//         return ret.primary && ret.primary.isNotEmpty();
//     };
//     ret.isEmpty = () => {
//         return !ret.isNotEmpty();
//     };
//     ret.isNotEmptySecondary = () => {
//         return ret.secondary && ret.secondary.isNotEmpty();
//     };
//     ret.isEmptySecondary = () => {
//         return !ret.isNotEmptySecondary();
//     };
//     ret.clearPrimary = () => {
//         ret.primary = createSummary();
//     };
//     ret.clearSecondary = () => {
//         ret.secondary = createSummary();
//     };
//     ret.setSummary = (summary) => {
//         if (!summary) {
//             return;
//         }

//         if (summary.condition.isPrimary()) {
//             ret.primary = summary;
//         } else {
//             ret.secondary = summary;
//         }
//     };
//     ret.formatTimestamp = (timestamp) => {
//         if (!timestamp) {
//             return null;
//         }

//         const isDateDisplay = ret.primary.condition.searchPeriod > 2;
//         const iso = constant.toDate(timestamp).toISOString();
//         const isoDate = iso.substring(5, 10);
//         const isoTime = iso.substring(11, 16);

//         return isDateDisplay ? isoDate : isoTime;
//     };
//     ret.addSummary = (list, user, summary) => {
//         if (summary.isEmpty()) {
//             return;
//         }

//         const chartDataDict = summary.condition.initSensorChartDataDict();

//         for (const detail of summary.detailList) {
//             if (!detail || detail.isEmpty()) {
//                 continue;
//             }

//             const key = detail.timestamp;

//             if (chartDataDict[key]) {
//                 const co2c = detail.co2c;
//                 const color = user.strategy.getChartColor(detail);

//                 chartDataDict[key] = createChartData(co2c, color, detail);
//             }
//         }

//         const chartDataList = Object.values(chartDataDict);

//         chartDataList.forEach((value, index) => {
//             const indexPrev = index - 1;
//             const indexNext = index + 1;
//             const prev = indexPrev >= 0 ? chartDataList[indexPrev] : createChartData();
//             const next = indexNext < chartDataList.length ? chartDataList[indexNext] : createChartData();

//             list[index] = list[index].concat(value.data(prev.isEmpty() && next.isEmpty()));
//         });
//     };
//     ret.getSensorChartDataList = (user) => {
//         const joinHeader = (valueList) => {
//             const create = (label) => {
//                 return [label, { role: "style" }, { role: "tooltip" }];
//             };
//             let header = ["timestamp"].concat(create("primary"));

//             if (ret.secondary.isNotEmpty()) {
//                 header = header.concat(create("secondary"));
//             }

//             return valueList ? [header].concat(valueList) : [header];
//         };

//         if (ret.isEmpty()) {
//             return joinHeader();
//         }

//         const resultDict = {};
//         const primaryChartDataDict = ret.primary.condition.initSensorChartDataDict();
//         const primaryKeyList = Object.keys(primaryChartDataDict);
//         const labelInterval = primaryKeyList.length / 8;

//         primaryKeyList.forEach((key, index) => {
//             const timestamp = index && (index % labelInterval) === 0 ? ret.formatTimestamp(key) : null;

//             resultDict[key] = [timestamp];
//         });

//         const resultList = Object.values(resultDict);

//         ret.addSummary(resultList, user, ret.primary);
//         ret.addSummary(resultList, user, ret.secondary);

//         return joinHeader(resultList);
//     };

//     return ret;
// }

export default createSummarySensor;