import constant, { StyleType } from "./constant";
import createHierarchy from "./model.hierarchy";
import createDetail, { Detail } from "./model.detail";
import { User } from "./model.user";


export class Strategy {
	public user: Partial<User>;

	protected readonly IconFace = "icon-face-";

	constructor(init?: Partial<User>) {
		this.user = init;
	}

	getHierarchy = () => {
		return this.user ? this.user.getCurrentHierarchy() : createHierarchy();
	}
	enabled = () => {
		/* vitual: SubClassで上書き */
		return true;
	}
	disabled = () => {
		return !this.enabled();
	}
	toDetail = (value: Detail | number): Detail => {
		return value && typeof value != 'number' && value?.co2c ? value : createDetail({ co2c: value as number });
	}
	geYellow = (value: Detail | number) => {
		return this.toDetail(value).co2c >= this.getHierarchy().lowerYellow;
	}
	geRed = (value: Detail | number) => {
		return this.toDetail(value).co2c >= this.getHierarchy().lowerRed;
	}
	getLevelRed = () => {
		/* vitual: SubClassで上書き */
		return constant.red();
	}
	getVentilationLevelColor = (value: Detail | number) => {
		if (this.toDetail(value).isEmpty()) {
			return constant.gray();
		}

		return this.geRed(value) ? this.getLevelRed() : this.geYellow(value) ? constant.yellow() : constant.green();
	}
	getFaceRed = () => {
		return "red";
	}
	getFaceColor = (value: Detail | number) => {
		if (this.toDetail(value).isEmpty()) {
			return "gray";
		}

		return this.geRed(value) ? this.getFaceRed() : this.geYellow(value) ? "yellow" : "green";
	}
	getFaceColorBlack = (value: Detail | number) => {
		return this.getFaceColor(value) + "-black";
	}
	getVentilationLevel = (value: Detail | number) => {
		return this.getHierarchy().getVentilationLevel(this.toDetail(value));
	}
	getChartColor = (value: Detail | number) => {
		return constant.getChartColor(this.getVentilationLevel(value));
	}
	srcFaceImage = (value: Detail | number) => {
		return constant.src(this.IconFace + this.getFaceColor(value));
	}
	srcFaceImageBlack = (value: Detail | number) => {
		return constant.src(this.IconFace + this.getFaceColorBlack(value));
	}
	srcTimerImage = (value: Detail | number) => {
		return constant.src("icon-timer-" + this.getFaceColor(value));
	}
	srcChartImage = (value: Detail | number) => {
		return constant.src("level-chart-" + this.getVentilationLevel(value));
	}
	bindStyle = (style: StyleType, detail: Detail, clickable: boolean): StyleType => {
		return Object.assign(
			style,
			{
				"--ventilation-level-color": this.getVentilationLevelColor(detail),
				"--chart-color": this.getChartColor(detail),
				"--cursor": clickable ? "pointer" : "default",
				"--pointer-events": "auto",
			}
		);
	}
	getDefaultScreen = () => {
		/* vitual: SubClassで上書き */
		return this.user.initialScreen;
	}
	getInitialScreen = () => {
		return this.user.initialScreen ? this.user.initialScreen : this.getDefaultScreen();
	}
	getTransitionScreen = () => {
		return this.getInitialScreen() === constant.screenList() ?
			constant.screenRanking() : this.getInitialScreen();
	}
	getSearchUnit = () => {
		return this.getInitialScreen() === constant.screenRanking() ?
			constant.searchUnitRanking() : constant.searchUnitFloor();
	}
	getUser = () => {
		return this.user;
	}
}
