/** センサー情報 */
export class Sensor {

    public sensorNumber: string;
    public hierarchyCode: string;
    public floorCode: string;
    public location: string;
    public locationRow: number;
    public locationCol: number;
    public sizeRow: number;
    public sizeCol: number;
    public sortOrder: number;
    public enableToUser: boolean;

    constructor(init?: Partial<Sensor>) {
        const locationDefault = 1;
        const sizeDefault = 3;
        const sortOrderDefault = 1;
        if (init) {
            Object.assign(this, init);
        }
        else {
            this.sensorNumber = null;
            this.hierarchyCode = null;
            this.floorCode = null;
            this.location = null;
            this.enableToUser = true;
        }
        this.sortOrder = init?.sortOrder ?? sortOrderDefault;
        this.sizeRow = init?.sizeRow ?? sizeDefault;
        this.sizeCol = init?.sizeCol ?? sizeDefault;
        this.locationRow = init?.locationRow ?? locationDefault;
        this.locationCol = init?.locationCol ?? locationDefault;


    }

    matchLocation = (row: number, col: number) => {
        return this.locationRow == row && this.locationCol == col;
    }

}

/** センサー情報作成 */
const createSensor = (model?: Partial<Sensor>) => {
    return new Sensor(model);
}

export default createSensor;
