import constant from "./constant";
import { AreaSearchCondition } from "./model.area.search.condition";
import { Hierarchy } from "./model.hierarchy";
import createSensor, { Sensor } from "./model.sensor";

export class PointSensor {
    public sensor: Sensor;
    public countGreen: number;
    public countYellow: number;
    public countRed: number;

    public visibleTooltip: boolean;
    public countClick: number


    constructor(init?: Partial<PointSensor>) {
        this.sensor = init?.sensor ?? createSensor();
        this.countGreen = init?.countGreen ?? 0;
        this.countYellow = init?.countYellow ?? 0;
        this.countRed = init?.countRed ?? 0;

        this.visibleTooltip = false;
        this.countClick = 0;

    }

    cg = () => this.countGreen;
    cy = () => this.countYellow;
    cr = () => this.countRed;
    add = (condition: AreaSearchCondition, lower: number, count: number) => lower >= condition.geCo2c ? count : 0;

    countTotal = () => {
        return this.cg() + this.cy() + this.cr();
    };
    count = (c: AreaSearchCondition, h: Hierarchy) => {
        return this.add(c, 0, this.cg()) + this.add(c, h.lowerYellow, this.cy()) + this.add(c, h.lowerRed, this.cr());
    };
    show = (c: AreaSearchCondition, h: Hierarchy) => {
        return (this.count(c, h) / (this.countTotal() || 1) >= c.gePercent) ||
            this.countTotal() == 0;
    };
    /** Area画面 色の判定 赤=>黄=>緑 の順で閾値以上(1%)の色を返す */
    getColor = () => {
        if (this.getPercentRed() >= constant.colorThreshold()) {
            return constant.red();
        }
        else if (this.getPercentYellow() >= constant.colorThreshold()) {
            return constant.yellow();
        }
        else if (this.getPercentGreen() >= constant.colorThreshold()) {
            return constant.green();
        }
        else {
            return constant.gray();
        }
    };
    getPercentGreen = () => {
        return this.cg() > 0 ? this.cg() / this.countTotal() : 0;
    };
    getPercentYellow = () => {
        return this.cy() > 0 ? this.cy() / this.countTotal() : 0;
    };
    getPercentRed = () => {
        return this.cr() > 0 ? this.cr() / this.countTotal() : 0;
    };
}

const createPointSensor = (model?: PointSensor) => {
    return new PointSensor(model);
};

export default createPointSensor;