import createSensor, { Sensor } from "./model.sensor";

/** 詳細情報 */
export class Detail {
    public temp: number;
    public humi: number;
    public co2c: number;
    public timestamp: number;
    public co2cSimpleList: number[];
    public tempSimpleList: number[];
    public humiSimpleList: number[];
    public sensor: Sensor;
    public errorReason: string;

    constructor(init?: Partial<Detail>) {
        this.temp = init?.temp ?? 0;
        this.humi = init?.humi ?? 0;
        this.co2c = init?.co2c ?? -1;
        this.timestamp = init?.timestamp ?? 0;
        this.co2cSimpleList = init?.co2cSimpleList ?? [];
        this.tempSimpleList = init?.tempSimpleList ?? [];
        this.humiSimpleList = init?.humiSimpleList ?? [];
        this.errorReason = init?.errorReason ?? "";
        this.sensor = init?.sensor ? createSensor(init.sensor) : createSensor(null);
    }
    isNotEmpty = () => {
        return this.co2c && this.co2c >= 0;
    }
    isEmpty = () => {
        return !this.isNotEmpty();
    }
}

/** 詳細情報作成 */
const createDetail = (model?: Partial<Detail>) => {
    return new Detail(model);
}
export default createDetail;
