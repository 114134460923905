import constant from "./constant";
//import funcCookie from "./function.cookie";
import funcLocalStorage from "./function.localstorage";

const yellowCo2MusicPath = require("../assets/audio/sound-co2-yellow.wav");
const redCo2MusicPath = require("../assets/audio/sound-co2-red.wav");


const storeRingState = {
    /** @type {Audio} */
    musicYellow: null,
    /** @type {Audio} */
    musicRed: null,
    /** @type {string[]} */
    ringSensorList: [] as any,
    /** @type {sensorRingInfo[]} */
    sensorRingInfoList: [],
};
type StoreRingState = typeof storeRingState;
/**
 * 音を鳴らす関連処理のStore
 */
const storeRing = {
    namespaced: true,
    state: storeRingState,
    getters: {
        /**
         * 黄の場合に鳴らす通知音を取得
         * @returns {Audio} 通知音
         */
        musicYellow: (state: StoreRingState) => {
            return state.musicYellow
        },
        /**
         * 赤の場合に鳴らす通知音を取得
         * @returns {Audio} 通知音
         */
        musicRed: (state: StoreRingState) => {
            return state.musicRed
        },
        /**
         * 通知音を鳴らす全てのセンサの一覧を取得
         * @returns {string[]} 通知音を鳴らす全てのセンサの一覧
        */
        ringSensorList: (state: StoreRingState) => {
            return state.ringSensorList
        },
        /**
         * 全てのセンサの通知音設定一覧を取得
         * @returns {sensorRingInfo[]} 全てのセンサの通知音設定一覧
        */
        sensorRingInfoList: (state: StoreRingState) => {
            return state.sensorRingInfoList
        },

        /**
         * センサが通知音をならす設定かつお休み時間帯以外か確認
         * @param {string} sensorNumber 通知音を確認したいセンサ
         * @returns {bool} 通知音をならすかつお休み時間帯以外の場合、true
         */
        isRingSensorAtTime: (state: StoreRingState) => (sensorNumber: string) => {
            const nowTimeValue = Number(constant.totime(constant.getNowUnixTime()).replace(':', ''));
            const ringSensorNumber = state.sensorRingInfoList
                .filter(x => sensorNumber.includes(x.sensorNumber))
                .filter(x => {
                    if (x.enableDoNotDisturb) {
                        // お休みモードONの場合、現在時刻がお休み時間外であることを条件に追加
                        const doNotDisturbTimeStartValue = Number(x.doNotDisturbTimeStart.replace(':', ''));
                        const doNotDisturbTimeEndValue = Number(x.doNotDisturbTimeEnd.replace(':', ''));
                        if (doNotDisturbTimeStartValue < doNotDisturbTimeEndValue) {
                            // start < end なら start<--->2400 と 0<--->end
                            return (nowTimeValue < doNotDisturbTimeStartValue || nowTimeValue > doNotDisturbTimeEndValue);
                        } else {
                            // start >= end なら end<-->start
                            return (nowTimeValue < doNotDisturbTimeStartValue && nowTimeValue > doNotDisturbTimeEndValue);
                        }
                    } else {
                        // お休みモードOFFの場合
                        return true;
                    }
                })
                .map(x => x.sensorNumber);
            return state.ringSensorList.some(x => (x == ringSensorNumber));
        },

        /**
         * センサが通知音をならす設定か確認
         * @param {string} sensorNumber 通知音を確認したいセンサ
         * @returns {bool} 通知音をならす場合、true
         */
        isRingSensor: (state: StoreRingState) => (sensorNumber: string) => {
            return state.ringSensorList.some(x => (x == sensorNumber));
        },

        /**
         * センサが通知音をならす設定かつお休み時間帯か確認
         * @param {string} sensorNumber 通知音を確認したいセンサ
         * @returns {bool} 通知音をならすかつお休み時間帯の場合、true
         */
        isDoNotDisturbSensor: (state: StoreRingState) => (sensorNumber: string) => {
            const nowTimeValue = Number(constant.totime(constant.getNowUnixTime()).replace(':', ''));
            const doNotDisturbSensorNumber = state.sensorRingInfoList
                .filter(x => sensorNumber.includes(x.sensorNumber))
                .filter(x => {
                    if (x.enableDoNotDisturb) {
                        // お休みモードONの場合、現在時刻がお休み時間外であることを条件に追加
                        const doNotDisturbTimeStartValue = Number(x.doNotDisturbTimeStart.replace(':', ''));
                        const doNotDisturbTimeEndValue = Number(x.doNotDisturbTimeEnd.replace(':', ''));
                        if (doNotDisturbTimeStartValue < doNotDisturbTimeEndValue) {
                            // start < end なら end<-->start
                            return (nowTimeValue >= doNotDisturbTimeStartValue && nowTimeValue <= doNotDisturbTimeEndValue);
                        } else {
                            // start >= end なら start<--->2400 と 0<--->end
                            return (nowTimeValue >= doNotDisturbTimeStartValue || nowTimeValue <= doNotDisturbTimeEndValue);
                        }
                    } else {
                        // お休みモードOFFの場合
                        return false;
                    }
                })
                .map(x => x.sensorNumber);
            return state.ringSensorList.some(x => (x == doNotDisturbSensorNumber));
        },

        /**
         * ブラウザが通知音をならす状態になっているか確認
         * @returns {bool} ブラウザが通知音をならす状態になっている場合、true
         */
        isEnableRing: (state: StoreRingState) => {
            return state.musicYellow != null && state.musicRed != null;
        },
    },
    mutations: {
        /**
         * 黄の場合に鳴らす通知音を設定
         * @param {Audio} music 通知音
         */
        setMusicYellow(state: StoreRingState, music: any) {
            console.log("setMusicYellow");
            state.musicYellow = music;
        },
        /**
         * 赤の場合に鳴らす通知音を設定
         * @param {Audio} music 通知音
         */
        setMusicRed(state: StoreRingState, music: any) {
            console.log("setMusicRed");
            state.musicRed = music;
        },
        /**
         * 通知音の設定をLocalStorageから読み込み
         */
        readRingSensorListByLocalStorage(state: StoreRingState) {
            // state.ringSensorList = funcCookie.readRingSensorListByCookie();
            state.ringSensorList = funcLocalStorage.readRingSensorListByLocalStorage();
        },
        /**
         * 全てのセンサの通知音設定一覧をLocalStorageから読み込み
         */
        readSensorRingInfoByLocalStorage(state: StoreRingState) {
            // state.ringSensorList = funcCookie.readRingSensorListByCookie();
            state.sensorRingInfoList = funcLocalStorage.readSensorRingInfoByLocalStorage();
        },
        /**
         * センサを通知音を鳴らす設定に変更
         * @param {string} sensorNumber 通知音を鳴らすセンサ
         */
        addRingSensor(state: StoreRingState, sensorNumber: any) {
            console.log("addRingSensor sensorNumber", sensorNumber);
            if (Array.isArray(state.ringSensorList)) {
                state.ringSensorList.push(sensorNumber);
            }
            else {
                state.ringSensorList = [sensorNumber];
            }
            // funcCookie.saveRingSensorListToCookie(state.ringSensorList);
            funcLocalStorage.saveRingSensorListToLocalStorage(state.ringSensorList);
        },
        /**
         * センサを通知音を鳴らさない設定に変更
         * @param {string} sensorNumber 通知音を鳴らさないセンサ
         */
        deleteRingSensor(state: StoreRingState, sensorNumber: any) {
            console.log("deleteRingSensor sensorNumber", sensorNumber);
            if (Array.isArray(state.ringSensorList)) {
                state.ringSensorList = state.ringSensorList.filter(x => (x != sensorNumber));
            }
            else {
                state.ringSensorList = [];
            }
            // funcCookie.saveRingSensorListToCookie(state.ringSensorList);
            funcLocalStorage.saveRingSensorListToLocalStorage(state.ringSensorList);
        },
        /**
         * 通知音を鳴らすセンサを一括で全て設定を変更
         * @param {string[]} allRingSensorList 通知音を鳴らす全てのセンサの一覧
         */
        setAllRingSensor(state: StoreRingState, allRingSensorList: any) {
            //全て追加
            console.log("setAllRingSensor", allRingSensorList);
            state.ringSensorList = allRingSensorList;
            // funcCookie.saveRingSensorListToCookie(allRingSensorList);
            funcLocalStorage.saveRingSensorListToLocalStorage(allRingSensorList);
        },
        /**
         * 全てのセンサを通知音を鳴らさない設定に変更
         */
        deleteAllRingSensor(state: StoreRingState) {
            state.ringSensorList = [];
            console.log("saveRingSensorListToCookie", constant);
            // funcCookie.deleteCookie(constant.ringSensorListCookieName());
            funcLocalStorage.deleteItem(constant.ringSensorListLocalStorageName());
        },

        /**
         * 全てのセンサの通知音設定を一括で全て変更
         * @param {sensorRingInfo[]} allSensorRingInfoList 全てのセンサの通知音設定一覧
         */
        setAllSensorRingInfo(state: StoreRingState, allSensorRingInfoList: any) {
            //全て追加
            console.log("setAllSensorRingInfo", allSensorRingInfoList);
            // TODO:ここでringSensorListにsetする場合、お休み時間以外でフィルターをかける
            // state.ringSensorList = allSensorRingInfoList.filter(x => x.isEnableRing == true).map((x) => x.sensorNumber);
            state.sensorRingInfoList = allSensorRingInfoList;
            // funcCookie.saveSensorRingInfoListToCookie(allSensorRingInfoList);
            funcLocalStorage.saveSensorRingInfoListToLocalStorage(allSensorRingInfoList);
        },

    },
    actions: {
        /**
         * @see {storeRing.mutations.setMusicYellow} 
         */
        setMusicYellow({ commit }: { commit: any }, music: any) {
            commit("setMusicYellow", music);
        },
        /**
         * @see {storeRing.mutations.setMusicRed} 
         */
        setMusicRed({ commit }: { commit: any }, music: any) {
            commit("setMusicRed", music);
        },
        /**
         * 通知用音楽の読み込み (Storeへの登録、iOSを考慮して再生＋停止でうまく再生できない場合登録しない)
         * @see {storeRing.mutations.setMusicYellow} 
         * @see {storeRing.mutations.setMusicRed} 
         */
        importRingMusic({ commit }: { commit: any }) {
            const errorMessage = "音声の読み込み 失敗 このブラウザで音声を再生するには操作する必要があります。";

            if (!this.getters[constant.storeRingGettersMusicYellow()]) {
                //黄色音の読み込み
                const musicYellow = new Audio(yellowCo2MusicPath);
                const playMusicYellowPromise = musicYellow.play();
                playMusicYellowPromise.then(() => {
                    musicYellow.pause();
                    // iPhoneの場合、pause()だけでは音が停止しないため再生位置を0に設定
                    musicYellow.currentTime = 0;
                    commit("setMusicYellow", musicYellow);
                }).catch(() => { console.log(errorMessage) });
            }
            if (!this.getters[constant.storeRingGettersMusicYellow()]) {
                //赤色音の読み込み
                const musicRed = new Audio(redCo2MusicPath);
                const playMusicRedPromise = musicRed.play();
                playMusicRedPromise.then(() => {
                    musicRed.pause();
                    // iPhoneの場合、pause()だけでは音が停止しないため再生位置を0に設定
                    musicRed.currentTime = 0;
                    commit("setMusicRed", musicRed);
                }).catch(() => { console.log(errorMessage) });
            }

        },

        /**
         * @see {storeRing.mutations.readRingSensorListByLocalStorage} 
         */
        readRingSensorListByLocalStorage({ commit }: { commit: any }) {
            commit("readRingSensorListByLocalStorage");
        },
        /**
         * @see {storeRing.mutations.readSensorRingInfoByLocalStorage} 
         */
        readSensorRingInfoByLocalStorage({ commit }: { commit: any }) {
            commit("readSensorRingInfoByLocalStorage");
        },
        /**
         * @see {storeRing.mutations.addRingSensor} 
         */
        addRingSensor({ commit }: { commit: any }, sensorNumber: any) {
            commit("addRingSensor", sensorNumber);
        },
        /**
         * @see {storeRing.mutations.deleteRingSensor} 
         */
        deleteRingSensor({ commit }: { commit: any }, sensorNumber: any) {
            commit("deleteRingSensor", sensorNumber);
        },
        /**
         * @see {storeRing.mutations.setAllRingSensor} 
         */
        setAllRingSensor({ commit }: { commit: any }, allRingSensorList: any) {
            commit("setAllRingSensor", allRingSensorList);
        },
        /**
         * @see {storeRing.mutations.deleteAllRingSensor} 
         */
        deleteAllRingSensor({ commit }: { commit: any }) {
            commit("deleteAllRingSensor");
        },

        /**
         * @see {storeRing.mutations.setAllSensorRingInfo} 
         */
        setAllSensorRingInfo({ commit }: { commit: any }, allSensorRingInfoList: any) {
            commit("setAllSensorRingInfo", allSensorRingInfoList);
        },

    },

};

export default storeRing;