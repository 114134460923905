import constant from "./constant";
import createSearchCondition, { SearchCondition } from "./model.search.condition";


export interface ICsvAsyncStoreParam {
    condition: SearchCondition;
    sensorCount: number;
}


export interface ICsvInfoResponse {
    condition: SearchCondition;
    downloadUrl: string;
    downloadExpired: string;
    fileSize: number;
    fileMimeType: string;
    succeed: boolean;
    errorMessage?: string;
    errorDetails?: string;
}

/** 検索条件 */
export class CsvInfo implements ICsvInfoResponse {
    public condition: SearchCondition;
    public succeed: boolean;
    public isCompleted: boolean;
    public downloadUrl: string;
    public downloadExpired: string;
    public fileSize: number;
    public fileMimeType: string;
    public executedDatetime: number;
    public sensorCount: number;
    public errorMessage?: string;
    public errorDetails?: string;


    private readonly day = (24 * 60 * 60);
    private readonly dataCount = 96;


    constructor(condition?: Partial<SearchCondition>, sensorCount?: number) {
        //検索条件
        this.condition = condition ? createSearchCondition(condition) : createSearchCondition(null);
        // this.condition.searchUnit = condition?.searchUnit ?? constant.searchUnitCsv();
        // this.condition.userKind = condition?.userKind ?? constant.userKindUnknown();
        // this.condition.userCode = condition?.userCode ?? null;
        // this.condition.hierarchyCode = condition?.hierarchyCode ?? null;
        // this.condition.sensorNumber = condition?.sensorNumber ?? null;
        // this.condition.searchDate = condition?.searchDate ?? 0;
        // this.condition.searchPeriod = condition?.searchPeriod ?? constant.searchPeriodDefault();
        // this.condition.partition = condition?.partition ?? constant.partitionPrimary();
        // this.condition.userCode = condition?.userCode ?? null;
        this.condition.executedDatetime = condition?.executedDatetime ?? constant.toUnixTime(new Date());
        //
        this.executedDatetime = constant.toUnixTime(new Date());
        this.sensorCount = sensorCount ?? 0;
        this.isCompleted = false;
    }

    setResponseData = (data: ICsvInfoResponse) => {
        this.downloadUrl = data.downloadUrl;
        this.downloadExpired = data.downloadExpired;
        this.fileSize = data.fileSize;
        this.fileMimeType = data.fileMimeType;
        this.succeed = data.succeed;
        this.errorMessage = data.errorMessage;
        this.errorDetails = data.errorDetails;
        this.isCompleted = true;
    }

    setResponseError = (errorDetails:string) => {
        this.succeed = false;
        //errorDetailsによって分岐
        if ( errorDetails.indexOf('timeout') != -1 || errorDetails.indexOf('Timeout')) {
            //strにtimeoutを含む場合の処理
            this.errorMessage = "タイムアウトになりました。期間を短くして再度実施してみてください。";
        }else{
            this.errorMessage = "システムエラーです。";
        }
        
        this.errorDetails = errorDetails;
        this.isCompleted = true;
    }

    /** 有効期限切れか */
    isExpired = () =>{
        const now = new Date().getTime();
        const expired = Date.parse(this.downloadExpired);
        return expired < now;
    }
}


/** CSV情報作成 */
const createCsvInfo = (condition?: Partial<SearchCondition>, sensorCount?: number) => {
    return new CsvInfo(condition, sensorCount);

};
export default createCsvInfo;