import constant from "./constant";
import createDetail, { Detail } from "./model.detail";
import createSearchCondition, { SearchCondition } from "./model.search.condition";

/** サマリ情報 */
export class Summary {

    public condition?: SearchCondition;
    public detailList?: Detail[];
    constructor(init?: Partial<Summary>) {
        this.condition = init?.condition ? createSearchCondition(init.condition) : createSearchCondition(null);
        this.detailList = init?.detailList ?
            init.detailList.map(detail => createDetail(detail)) : [];

    }

    isNotEmpty = () => {
        return this.detailList && this.detailList.length;
    }

    isEmpty = () => {
        return !this.isNotEmpty();
    }

    getMaxDetail = () => {
        if (this.isEmpty()) {
            return null;
        }

        let maxDetail = createDetail();

        for (const detail of this.detailList) {
            if (maxDetail.co2c < detail.co2c) {
                maxDetail = detail;
            }
        }

        return maxDetail;
    }

    getDisplayDetailList = (user) => {
        return user.isUser() ? this.detailList.filter((val) => val.sensor.enableToUser) : this.detailList;
    }

    countRankingDetailPerPage = (isLandscape: boolean, user) => {
        const base = isLandscape ? 5 : 3;
        const count = this.getDisplayDetailList(user).length;

        return base < count ? base : count;
    }

    getRankingPageList = (isLandscape: boolean, user) => {
        const newPage = () => {
            return {
                number: 0,
                detailList: [],
                add(detail) {
                    this.detailList.push(detail);
                },
                isEmpty() {
                    return this.detailList.length === 0;
                },
                match(sensor) {
                    return sensor && this.detailList.some((val) => val.sensor.sensorNumber === sensor.sensorNumber);
                },
            };
        };
        const pageList = [];
        const addPage = (item) => {
            if (item.isEmpty()) {
                return newPage();
            }

            while (item.detailList.length < this.countRankingDetailPerPage(isLandscape, user)) {
                item.detailList.push(createDetail());
            }

            item.number = pageList.length;
            pageList.push(item);

            return newPage();
        };
        let page = newPage();

        for (const detail of this.getDisplayDetailList(user)) {
            page.add(detail);

            if (page.detailList.length >= this.countRankingDetailPerPage(isLandscape, user)) {
                page = addPage(page);
            }
        }

        page = addPage(page);

        if (user.isUser() && !(user.getAddPage().toUpperCase() === "OFF")) {
            pageList.push({ dispImage: 0 });
            return pageList;
        }

        return pageList;
    }

    getMinMaxCo2c = (hierarchy) => {
        let min = hierarchy ? hierarchy.lowerGreen : constant.lowerDefault();
        let max = hierarchy ? hierarchy.lowerRed : constant.lowerDefault();

        for (const detail of this.detailList) {
            for (const co2c of detail.co2cSimpleList) {
                min = co2c < min ? co2c : min;
                max = co2c > max ? co2c : max;
            }
        }

        const margin = Math.floor((max - min) / 10);

        return { min: min - margin, max: max + margin };
    }

    //Memo:マージ後要見直し
    // sortRanking = () => {
    //     this.detailList.sort((a, b) => {
    //         return b.co2c - a.co2c;
    //     });
    // }
    sortRanking = (type: any) => {
        this.detailList.sort((a, b) => {
            return b[type] - a[type];
        });
    };

    sortFloor = () => {
        this.detailList.sort((a, b) => {
            return a.sensor.sortOrder - b.sensor.sortOrder;
        });
    }

    getDisplaySensorList = (user) => {
        const sensorList = [];

        if (this.isEmpty()) {
            return sensorList;
        }

        for (const detail of this.getDisplayDetailList(user)) {
            const sensor = detail.sensor;

            if (sensor && !sensorList.some((val) => val.sensorNumber === sensor.sensorNumber)) {
                sensorList.push(detail.sensor);
            }
        }

        sensorList.sort((a, b) => { return a.sortOrder - b.sortOrder; });

        return sensorList;
    }

    getLatestTimestamp = () => {
        if (this.isEmpty()) {
            const executedDatetime = this.condition?.executedDatetime;
            return executedDatetime != null ? executedDatetime : 0;
        }
        return Math.max(...this.detailList.map((d) => d.timestamp));
    }

}

/** サマリ情報作成 */
const createSummary = (model?: Partial<Summary>) => {
    return new Summary(model);
}

export default createSummary;
