import constant from "./constant";
import { Detail } from "./model.detail";
import createFloor, { Floor } from "./model.floor";
import { Sensor } from "./model.sensor";

/** 階層情報 */
export class Hierarchy {
    public hierarchyCode: string;
    public hierarchyName: string;
    public lowerGreen: number;
    public lowerYellow: number;
    public lowerRed: number;
    public upperRed: number;
    public cycleInterval: number;
    public parentHierarchyCode: string;
    public childHierarchyList: Hierarchy[];
    public floorList: Floor[];

    private readonly CycleIntervalDefault = 0;

    constructor(init?: Partial<Hierarchy>) {
        this.hierarchyCode = init?.hierarchyCode ?? null;
        this.hierarchyName = init?.hierarchyName ?? null;
        this.lowerGreen = init?.lowerGreen ?? constant.lowerDefault();
        this.lowerYellow = init?.lowerYellow ?? constant.lowerDefault();
        this.lowerRed = init?.lowerRed ?? constant.lowerDefault();
        this.upperRed = init?.upperRed ?? constant.lowerDefault();
        this.cycleInterval = init?.cycleInterval ?? this.CycleIntervalDefault;
        this.parentHierarchyCode = init?.parentHierarchyCode ?? null;
        this.childHierarchyList = init?.childHierarchyList ?? [];
        this.floorList = init?.floorList ?
            init.floorList.map(floor => createFloor(floor)) : [];

    }

    createRange=(level, value: number)=> {
        return {
            level: level,
            value: value,
            gt: (co2c: number) => co2c > value
        };
    }

    getRangeList = () => {
        const RangeGreen = 4;
        const RangeYelow = 3;
        const RangeRed = 3;
        const rangeList = [];

        // HACK:あとで記述見直し
        for (let i = 1; i <= RangeGreen; i++) {
            const l = i;
            const v = this.lowerGreen + (this.lowerYellow - this.lowerGreen) / RangeGreen * (i - 1);

            rangeList.push(this.createRange(l, v));
        }

        for (let i = 1; i <= RangeYelow; i++) {
            const l = i + RangeGreen;
            const v = this.lowerYellow + (this.lowerRed - this.lowerYellow) / RangeYelow * (i - 1);

            rangeList.push(this.createRange(l, v));
        }

        for (let i = 1; i <= RangeRed; i++) {
            const l = i + RangeGreen + RangeYelow;
            const v = this.lowerRed + (this.upperRed - this.lowerRed) / RangeRed * (i - 1);

            rangeList.push(this.createRange(l, v));
        }

        return rangeList;
    }

    getVentilationLevel=(detail: Detail)=> {
        if (!detail) {
            return constant.levelDefault();
        }

        if (detail.isEmpty()) {
            return constant.levelDefault();
        }

        if (detail.co2c <= this.lowerGreen) {
            return constant.levelMin();
        }

        if (detail.co2c >= this.upperRed) {
            return constant.levelMax();
        }

        let level = constant.levelMin();

        for (const range of this.getRangeList()) {
            if (range.gt(detail.co2c) && range.level > level) {
                level = range.level;
            }
        }

        return level;
    }

    getFloorDetailList=(detailList: Detail[]) =>{
        const fdDict = {};

        if (!detailList || !detailList.length) {
            return [];
        }

        // HACK:あとで記述見直し
        for (const floor of this.floorList) {
            fdDict[floor.floorCode] = {
                floor: floor,
                detailList: [],
                getDetail(row: number, col: number) {
                    for (const detail of this.detailList) {
                        if (detail.sensor.matchLocation(row, col)) {
                            return detail;
                        }
                    }

                    return null;
                },
                match(sensor: Sensor) {
                    return sensor && this.detailList.some((detail) => detail.sensor.sensorNumber === sensor.sensorNumber);
                },
            };
        }

        for (const detail of detailList) {
            const key = detail.sensor.floorCode;

            if (key in fdDict) {
                fdDict[key].detailList.push(detail);
            }
        }

        return Object.values(fdDict);
    }

    useCycle = () => {
        return this.cycleInterval > 0;
    }

    useTransition = () => {
        return "animation-off";
    }

    getInterval = () => {
        return this.useCycle() ? this.cycleInterval * 1000 : 6000;
    }
    

}

/** 階層情報作成 */
const createHierarchy = (model?: Partial<Hierarchy>) => {
    return new Hierarchy(model);
};

export default createHierarchy;