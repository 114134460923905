import { AxiosRequestConfig,ResponseType } from "axios";

export class AreaCsvRequestConfig implements AxiosRequestConfig{
    public headers: any;
    public responseType: ResponseType;

    constructor(init?: Partial<AreaCsvRequestConfig>) {
        if (!init?.headers){
            this.headers = {
                "Ocp-Apim-Subscription-Key": process.env.VUE_APP_OCP_APIM_SUBSCRIPTION_KEY,
                "Ocp-Apim-Trace": Boolean(process.env.VUE_APP_OCP_APIM_TRACE),
                Accept: 'application/zip',
            }
        }
        if (!init?.responseType){
            this.responseType = 'arraybuffer';
        }
    }

}

const createAreaCsvRequestConfig = (model?:AreaCsvRequestConfig) => {
    return new AreaCsvRequestConfig(model);
};

export default createAreaCsvRequestConfig;