
export class UserPassword {
    public userCode: string = "";
    public password: string = "";

    constructor(init?: UserPassword) {
        if (!init) {
            Object.assign(this, init);
        }
    }

}

const createUserPassword = (model) => {
    return new UserPassword(model);
};

export default createUserPassword;