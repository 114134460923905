import { AxiosBasicCredentials } from "axios";
import { RequestConfig } from "./model.request.config";
import { UserPassword } from "./model.user.password";


export class BasicAuthentication extends RequestConfig {

    public auth: AxiosBasicCredentials;

    constructor(userPassword?: Partial<UserPassword>) {
        super();
        this.auth = {
            username: userPassword.userCode,
            password: userPassword.password,
        };

    }

}

const createBasicAuthentication = (userPassword: UserPassword) => {
    return new BasicAuthentication(userPassword);
};

export default createBasicAuthentication;