import Vue from 'vue';
import Vuex from 'vuex';
import storeUser from './store.user';
import storeSummary from './store.summary';
import storeDownload from './store.download';
import storeDownloadArea from './store.download.area';
import storeArea from "./store.area";
import storeRing from "./store.ring"
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user: storeUser,
        summary: storeSummary,
        download: storeDownload,
        downloadArea: storeDownloadArea,
        area: storeArea,
        ring: storeRing,
    },

});

export default store;