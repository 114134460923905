import constant from "./constant";
import createChartData, { ChartData } from "./model.chart.data";

/** 検索条件 */
export class SearchCondition {
    public searchUnit: number;
    public userKind: number;
    public userCode: string;
    public hierarchyCode: string;
    public sensorNumber: string;
    public searchDate: number;
    public searchPeriod: number;
    public partition: number;
    public executedDatetime: number;

    private readonly day = (24 * 60 * 60);
    private readonly dataCount = 96;


    constructor(init?: Partial<SearchCondition>) {
        this.searchUnit = init?.searchUnit ?? constant.searchUnitUnknown();
        this.userKind = init?.userKind ?? constant.userKindUnknown();
        this.userCode = init?.userCode ?? null;
        this.hierarchyCode = init?.hierarchyCode ?? null;
        this.sensorNumber = init?.sensorNumber ?? null;
        this.searchDate = init?.searchDate ?? 0;
        this.searchPeriod = init?.searchPeriod ?? constant.searchPeriodDefault();
        this.partition = init?.partition ?? constant.partitionPrimary();
        this.userCode = init?.userCode ?? null;
        this.executedDatetime = init?.executedDatetime ?? 0;
    }

    isSearchUnitSensor = () => {
        return this.searchUnit == constant.searchUnitSensor();
    }

    isPrimary = () => {
        return this.partition === constant.partitionPrimary();
    }

    getSearchDateStart = () => {
        return this.searchDate - (this.day * (this.searchPeriod - 1));
    }

    getSearchDateUnit = () => {
        return Math.floor(this.day * this.searchPeriod / this.dataCount);
    }

    initSensorChartDataDict = (): { [key: number]: ChartData } => {
        const dict = {};
        const start = this.getSearchDateStart();
        const unit = this.getSearchDateUnit();

        for (let i = 0; i < this.dataCount; i++) {
            const timestamp = start + (i * unit);

            dict[timestamp] = createChartData();
        }

        return dict;
    }

}


/** 検索条件作成 */
const createSearchCondition = (model?: Partial<SearchCondition>) => {
    return new SearchCondition(model);

};

export default createSearchCondition;