import createUserPassword from "./model.user.password";
import createBasicAuthentication from "./model.basic.authentication";
import createRequestConfig from "./model.request.config";
import createAreaCsvRequestConfig from "./model.area.csv.request.config";
import createCell from "./model.cell";
import createFloor from "./model.floor";
import createHierarchy from "./model.hierarchy";
import createUser from "./model.user";
import createSensor from "./model.sensor";
import createSearchCondition from "./model.search.condition";
import createSummary from "./model.summary";
import createSummarySensor from "./model.summary.sensor";
import createDetail from "./model.detail";
import createHeader from "./model.header";
import createFab from "./model.fab";
import createChartData from "./model.chart.data";
import createStrategy from "./model.strategy";
import createAreaSearchCondition from "./model.area.search.condition";
import createPoint from "./model.point";
import createArea from "./model.area";
import createCsvInfo from "./model.csv.info";

const models = {
    createUserPassword,
    createBasicAuthentication,
    createRequestConfig,
    createAreaCsvRequestConfig,
    createCell,
    createFloor,
    createHierarchy,
    createUser,
    createSensor,
    createSearchCondition,
    createSummary,
    createSummarySensor,
    createDetail,
    createHeader,
    createFab,
    createChartData,
    createStrategy,
    createAreaSearchCondition,
    createPoint,
    createArea,
    createCsvInfo,
};

export default models; 