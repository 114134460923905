<template>
    <v-main class="main">
        <ok-dialog-component
            :propGetTitle="() => '認証エラー'"
            :propGetMessage="() => '認証に失敗しました...'"
            ref="dialog"
        />
    </v-main>
</template>

<script>
import store from "../store";
import constant from "../store/constant";
import OkDialogComponent from "../components/OkDialogComponent";

const vm = {
    components: {
        OkDialogComponent,
    },
    data() {
        return {
            user: null,
            summary: null,
            unsubscribe: () => {},
        };
    },
    computed: {
        strategy() {
            return this.user.strategy;
        },
    },
    methods: {
        unauthenticated() {
            return !(this.user && this.summary);
        },
        dispatchLogin() {
            constant.setQuery(this.$airoco, this.$route);
            store.dispatch(constant.storeUserLogin(), constant.getQuery(this.$airoco));
        },
        dispatchSummary() {
            store.dispatch(
                constant.storeSummarySearch(),
                this.user.createSearchCondition()
            );
        },
        transition() {
            if (this.unauthenticated()) {
                return;
            }

            this.$router.push({
                name: this.strategy.getTransitionScreen(),
                params: {
                    propUser: this.user,
                    propSummary: this.summary,
                    propSearchUnit: this.strategy.getSearchUnit(),
                },
                query: constant.getQuery(this.$airoco),
            });
        },
        openDialog() {
            this.$refs.dialog.show = true;
        },
    },
    created() {
        this.unsubscribe = store
            .subscribe((mutation, state) => {
                if (mutation.type === constant.storeUserLogin()) {
                    if (!state.user.user) {
                        this.openDialog();
                        return;
                    }

                    this.user = state.user.user;
                    this.dispatchSummary();
                } else if (mutation.type === constant.storeSummarySearch()) {
                    this.summary = state.summary.summary;
                    this.transition();
                }
            })
            .bind(this);
        this.dispatchLogin();
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};

export default vm;
</script>
