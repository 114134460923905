import constant from "./constant";

export class AreaSearchCondition {
    public userKind: number;
    public userCode: string;
    public hierarchyCode: string;
    public searchDateStart: number;
    public searchDateEnd: number;
    public searchTimeStart: null;
    public searchTimeEnd: null;
    public geCo2c: number;
    public gePercent: number;


    constructor(init?: Partial<AreaSearchCondition>) {
        this.userKind = init?.userKind ?? constant.userKindUnknown();
        this.userCode = init?.userCode ?? null;
        this.hierarchyCode = init?.hierarchyCode ?? null;
        this.searchDateStart = init?.searchDateStart ?? 0;
        this.searchDateEnd = init?.searchDateEnd ?? 0;
        this.searchTimeStart = init?.searchTimeStart ?? null;
        this.searchTimeEnd = init?.searchTimeEnd ?? null;
        this.geCo2c = init?.geCo2c ?? 0;
        this.gePercent = init?.gePercent ?? 0;
    }
    getSearchDay = () => {
        return Math.floor((this.searchDateEnd - this.searchDateStart) / (24 * 60 * 60)) + 1;
    }
    getSearchPeriod = () => {
        const sd = this.getSearchDay();
        const spList = constant.createSearchPeriodList();

        for (const sp of spList) {
            if (sd <= sp.value) {
                return sp;
            }
        }
        return spList[spList.length - 1];
    }
    getSearchPeriodValue = () => {
        return this.getSearchPeriod().value;
    }
}

const createAreaSearchCondition = (model?: AreaSearchCondition) => {
    return new AreaSearchCondition(model);
};

export default createAreaSearchCondition;