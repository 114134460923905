///<reference path="./constant.ts" />
import axios from "axios";
import models from "./models";
import constant from "./constant";
import { Summary } from "./model.summary";

// interface StoreSummaryState {
//     summary: Summary; // null summary null許可できる
//     processing: boolean;
//     summaryFloor: Summary;
//     sensorDataDictionary: any;
//     searchDatePrimary: number;
//     searchDateSecondary: number;
//     searchPeriod: any;
//     searchSecondaryFlag: boolean;
// }
const storeSummaryState = {
    /** summary 元データ */
    summary: null as null|Summary,
    processing: false,
    /** フロア画面ランキング画面のSummary 元データ */
    summaryFloor: null as null|Summary,
    /** センサ画面の元データ Dictionary で key は SensorNumber */
    sensorDataDictionary: {} as any,
    /** センサー検索条件:検索日Primary (UnixTime)*/
    searchDatePrimary: constant.toUnixTime(constant.today()),
    /** センサー検索条件:検索日Secondary (UnixTime)*/
    searchDateSecondary: constant.toUnixTime(constant.today().setDate(constant.today().getDate() - 7)),
    /** センサー検索条件:検索日付間隔*/
    searchPeriod: constant.createSearchPeriodDefault(),
    /** センサー検索条件:Secondary検索の有効フラグ*/
    searchSecondaryFlag: false,
};
type StoreSummaryState = typeof storeSummaryState;

const storeSummary = {
    namespaced: true,
    state: storeSummaryState,
    getters: {
        /** summary 元データ */
        summary: (state: StoreSummaryState) => {
            return state.summary
        },
        /** フロア画面ランキング画面のSummary 元データ */
        summaryFloor: (state: StoreSummaryState) => {
            return state.summaryFloor
        },
        /** センサ画面の元データ Dictionary で key は SensorNumber */
        sensorData: (state: StoreSummaryState) => (sensorNumber: any) => {
            console.log("store sensorData", sensorNumber, state.sensorDataDictionary, state.sensorDataDictionary[sensorNumber]);
            if (sensorNumber == null || !state.sensorDataDictionary[sensorNumber]) {
                console.log("store sensorData null", sensorNumber, state.sensorDataDictionary, state.sensorDataDictionary[sensorNumber]);
                return models.createSummarySensor();
            }
            else {
                return state.sensorDataDictionary[sensorNumber];
            }
        },
        /**
         * センサー検索条件:検索日Primary (UnixTime)
         * @returns {number}
         */
        searchDatePrimary: (state: StoreSummaryState) => {
            return state.searchDatePrimary
        },
        /** 
         * センサー検索条件:検索日Secondary  (UnixTime)
         * @returns {number}
         */
        searchDateSecondary: (state: StoreSummaryState) => {
            return state.searchDateSecondary
        },
        /** 
         * センサー検索条件:検索日付間隔
         * @returns {import("./constant").SearchPeriod}
        */
        searchPeriod: (state: StoreSummaryState) => {
            return state.searchPeriod
        },
        /** 
         * センサー検索条件:検索日付間隔
         * @returns {import("./constant").SearchPeriod}
        */
        searchPeriodValue: (state: StoreSummaryState) => {
            return state.searchPeriod.value
        },

        /**
         * センサー検索条件:Secondary検索の有効フラグ
         * @returns {boolean}
         */
        searchSecondaryFlag: (state: StoreSummaryState) => {
            return state.searchSecondaryFlag
        },
    },
    mutations: {
        /** 検索処理 */
        search(state: StoreSummaryState, result: any) {
            state.summary = models.createSummary(result);

            if (state.summary.condition?.isSearchUnitSensor()) {
                //センサごとの検索
                const sensorNumber = state.summary.condition.sensorNumber;
                if (!state.sensorDataDictionary[sensorNumber]) {
                    state.sensorDataDictionary[sensorNumber] = models.createSummarySensor();
                }
                //検索条件によってPrimary/Secondaryを切り替えてセット
                state.sensorDataDictionary[sensorNumber].setSummary(state.summary);
                
                //変更通知のため再セット
                state.sensorDataDictionary = Object.assign({}, state.sensorDataDictionary);

                console.log("search sensor", sensorNumber, state.sensorDataDictionary, state.sensorDataDictionary[sensorNumber], result);
            } else {
                console.log("search floor", result);
                //フロア画面の検索
                state.summaryFloor = models.createSummary(result);
            }
        },
        /**
         * センサー検索条件:検索日Primary (UnixTime)
         * @param {number} value 
         */
        setSearchDatePrimary(state: StoreSummaryState, value: any) {
            state.searchDatePrimary = value;
        },
        /**
         * センサー検索条件:検索日Secondary (UnixTime)
         * @param {number} value 
         */
        setSearchDateSecondary(state: StoreSummaryState, value: any) {
            state.searchDateSecondary = value;
        },
        /**
         * センサー検索条件:検索日付間隔
         * @param {import("./constant").SearchPeriod} value 
         */
        setSearchPeriod(state: StoreSummaryState, value: any) {
            state.searchPeriod = value;
        },
        /**
         * センサー検索条件:Secondary検索の有効フラグ
         * @param {boolean} value 
         */
        setSearchSecondaryFlag(state: StoreSummaryState, value: any) {
            state.searchSecondaryFlag = value;
        },
    },
    actions: {
        /** 
         * 検索処理
         * @see {storeSummary.mutations.search} 
         */
        search({ commit }: { commit: any }, condition: any) {
            //commit("startLoading");

            return axios.post(constant.urlSummary(), condition, models.createRequestConfig())
                .then((response) => {
                    commit("search", response.data);
                })
                .catch(() => {
                });
        },
        /**
         * @see {storeSummary.mutations.setSearchDatePrimary} 
         */
        setSearchDatePrimary({ commit }: { commit: any }, value: any) {
            commit("setSearchDatePrimary", value);
        },
        /**
         * @see {storeSummary.mutations.setSearchDateSecondary} 
         */
        setSearchDateSecondary({ commit }: { commit: any }, value: any) {
            commit("setSearchDateSecondary", value);
        },
        /**
         * @see {storeSummary.mutations.setSearchPeriod} 
         */
        setSearchPeriod({ commit }: { commit: any }, value: any) {
            commit("setSearchPeriod", value);
        },
        /**
         * @see {storeSummary.mutations.setSearchSecondaryFlag} 
         */
        setSearchSecondaryFlag({ commit }: { commit: any }, value: any) {
            commit("setSearchSecondaryFlag", value);
        },
    },

};

export default storeSummary;