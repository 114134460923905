import { AxiosRequestConfig } from "axios";

export class RequestConfig implements AxiosRequestConfig{
    
    public headers: any;

    constructor(init?: Partial<RequestConfig>) {
        if (!init?.headers){
            this.headers = {
                "Ocp-Apim-Subscription-Key": process.env.VUE_APP_OCP_APIM_SUBSCRIPTION_KEY,
                "Ocp-Apim-Trace": Boolean(process.env.VUE_APP_OCP_APIM_TRACE),
            }
        }
    }
}

const createRequestConfig = (model?:RequestConfig) => {
    return new RequestConfig(model);
};

export default createRequestConfig;