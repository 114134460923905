<template>
    <v-app class="content">
        <v-main class="main" :style="bindStyle">
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import Vue from 'vue';

import mixinApp from "./store/mixin.app";

const vm = {
    mixins: [mixinApp],
    name: "app",
};

export default Vue.extend(vm);
</script>

<style>
@import 'assets/css/styles.min.css';

.v-application {
    font-family: "Noto Sans Japanese", sans-serif !important;
}

.content {
    width: 100vw;
}

.main {
    background-color: var(--main-background-color);
}

.dialog-card {
    height: 45vh;
    overflow: hidden;
}

.dialog-header {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: large;
    margin-left: 1vw;
}

.dialog-content {
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog-content span {
    font-size: xx-large;
}

.dialog-footer {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1vw;
}

.dialog-button {
    width: 10vw;
    margin-left: 1vw;
}

.sensor-ring-table.theme--dark.v-data-table.v-data-table--fixed-header thead th{
    background: #404040 !important;
    color:white !important;
    border-top :1px solid rgb(255 255 255 / 12%);
    border-bottom :1px solid rgb(255 255 255 / 12%);
}
.sensor-ring-table.theme--dark.v-data-table tbody th{
    background-color: #2f2f2f !important;
}

.sensor-ring-table.theme--dark.v-data-table tbody tr{
    background-color: #2A2A2A !important;
}
.sensor-ring-table.theme--dark.v-data-table tbody tr:hover{
    background-color: #1e1e1e !important;
}

.sensor-ring-table-card-text span{
    font-size: large;
}
.sensor-ring-table-card-text.is-iphone span{
    font-size: small;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    /* iconのcolorをwhiteにできなかったため色を反転 */
    filter: invert(1);
    /* iconを無効 */
    /* display: none; */
}

.widthMin{
    width: 100px!important;
}
.widthMin2{
    width: 180px!important;
    border-left: 1px dashed #404040;
    opacity: 1;
}
th.widthMin2{
    border-left: 1px dashed #616161;
}
td.widthMin2{
    border-left: 1px dashed #404040;
}
.v-application .v-overlay {
    background: #00000010 0% 0% no-repeat padding-box!important;
    opacity: 1!important;
    backdrop-filter: blur(10px)!important;
}

.v-text-field.all-do-not-disturb-time > .v-input__control > .v-input__slot::before,
.v-text-field.all-do-not-disturb-time > .v-input__control > .v-input__slot::after {
    border: 1px solid #616161!important;
    bottom: 1px !important;
}
</style>
